"use client";
import { Field, Formik, Form } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { FaArrowLeftLong } from "react-icons/fa6";
import GetStartedStep2 from "./components/step2";
import GetStartedStep3 from "./components/step3";
import GetStartedStep4 from "./components/step4";
import GetStartedStep5 from "./components/step5";
import FinalStep from "./components/finalstep";
import { Step1 } from "./components/Steps";
import StepShape from "./components/step1";
import Loader from "../loader/loader";
import { useSearchParams } from "react-router-dom";
import image from "../../../assets/images/get-started/get-started-banner.webp";
import { useTranslation } from "react-i18next";

function GetStarted() {

  const {t} = useTranslation()

  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [answers, setAnswers] = React.useState("");
  const [shape, setShape] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const redirect = searchParams.get("redirect");
    const shape = searchParams.get("shape");

    if (redirect) {
      setAnswers("Engagement Ring");
      if (shape !== null) {
        setShape(shape);
        setStep(2);
      }
    }
  }, [searchParams]);

  const proceed = (value: any) => {
    setAnswers(value);
    setStep(1);
  };

  const backHandler = () => {
    setStep(step - 1);
  };

  const initialValues = {
    title: answers,
    shape: shape || "",
    fileUrl: "",
    legalName: "",
    email: "",
    phoneNumber: "",
    budget: "",
    description: "",
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      from: values.email,
      to: "bespoke@ecorocks.es",
      subject: "Bespoke",
      data: [
        { key: "Name", value: values.legalName },
        { key: "Phone", value: values.phoneNumber },
        { key: "Email", value: values.email },
        { key: "title", value: values.title },
        { key: "shape", value: values.shape },
        { key: "fileUrl", value: values.fileUrl },
        { key: "budget", value: values.budget },
        { key: "description", value: values.description },
      ],
    };
    setLoading(true);

    fetch("https://api.ecorocksdenia.com/api/auth/contact", {
      body: JSON.stringify(payload),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setStep(0);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = Yup.object().shape({
    legalName: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string().required("Mobile Number is required"),
    // Add other fields as needed
  });
  const validationSchema2 = Yup.object().shape({
    budget: Yup.string(),
  });

  const nextHandler = async (validateForm: any) => {
    if (step === 4) {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
      } else {
        console.log(errors);
        // Optionally, show a message or handle errors
      }
    } else {
      setStep(step + 1);
    }
  };

  return (
    <div
      className={` widow-main py-[50px] md:py-[100px] relative z-[2] bg-[#F4F1EC] overflow-hidden`}
      id="getStart"
    >
      {loading && <Loader />}

      <div className="widow-inner">
        <motion.p
          initial={{ x: 100, y: 100, opacity: 0 }}
          whileInView={{ x: 0, y: 0, opacity: 1 }}
          viewport={{ once: true }}
          className="text_60 text-[#000000] font-[500]  text-center"
        >
          {t("getStarted.title")}
        </motion.p>

        <div className="bg-[#094C3B] w-full min-h-[500px] rounded-[16px] mt-[32px] sm:mt-[60px] relative overflow-hidden sm:p-[32px] lg:p-[80px]">
          <img
            src={image}
            className="w-full h-full object-cover left-0 top-0 absolute z-[1]"
          />

          <div className="w-full h-full p-7   z-[2] relative">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setFieldValue, validateForm }) => (
                <Form>
                  {step === 0 ? <Step1 proceed={proceed} /> : null}
                  {step > 0 && step < 6 && (
                    <div className="flex justify-between">
                      <div>
                        <div
                          className="arrow-main md:top-auto top-4 absolute lg:left-28 md:left-14  left-5 border p-3 w-[50px] h-[50px] mb-2 rounded-full sm:scale-[1] scale-[0.5]"
                          data-state="1"
                          onClick={() => backHandler()}
                        >
                          <div className="arrow-wrapper ">
                            <span className="arrow-span text-white">
                              <FaArrowLeftLong className="arr-1   a-btn-1 text-2xl" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex md:gap-6 gap-2">
                        <div className="md:px-[30px] px-2  md:py-1 py-1 flex items-center justify-center border border-white rounded-[8px] hover:bg-white/35 bg-white/20 md:text-base text-xs text-white">
                          <span className="text-inherit">{t("getStarted.selectedring")}</span>
                        </div>
                        {step > 1 && (
                          <div className="md:px-[30px] px-2  md:py-1 py-1 flex items-center justify-center border border-white rounded-[8px] hover:bg-white/35 bg-white/20 md:text-base text-xs text-white">
                            <span className="text-inherit">{t(`getStarted.${values.shape}`)}</span>
                          </div>
                        )}
                      </div>
                      <div>
                        {step > 1 && step < 6 && (
                          <button
                            type="button"
                            onClick={() => nextHandler(validateForm)}
                            className="text-[#CDB972] font-semibold  my-auto"
                          >
                            {t("getStarted.nextButton")}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {step === 1 ? (
                    <StepShape proceed={setFieldValue} setStep={setStep} />
                  ) : null}
                  {step === 2 ? (
                    <GetStartedStep2 setFieldValue={setFieldValue} />
                  ) : null}
                  {step === 3 ? <GetStartedStep5 errors={errors} /> : null}
                  {step === 4 ? <GetStartedStep3 errors={errors} /> : null}
                  {step === 5 ? <GetStartedStep4 /> : null}
                  {step === 6 ? (
                    <FinalStep setStep={setStep} loading={loading} />
                  ) : null}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
