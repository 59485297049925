import React from "react";
import PageHeaderSecondary from "../molecules/PageHeaderSecondary";
import AboutDiscover from "./discover";
import WhoWeAre from "../homepage/whoWeAre";
import HomePageBespoke from "../homepage/homepageBespoke";
import FAQs from "../homepage/faqs";
import DontMiss from "../homepage/dontMiss";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const {t} = useTranslation()
  return (
    <div>
      <PageHeaderSecondary title_1={t("aboutTitle")} />
      <AboutDiscover />
      <WhoWeAre />
      <div className="bg-[#F4F1EC] widow-main py-[50px] md:py-[100px]  flex flex-col text-black">
        <div className="widow-inner">
          <HomePageBespoke
            color={
              "   hover:bg-[#094C3B] text-black hover:text-white border-[#161616] "
            }
          />
        </div>
      </div>
      <FAQs />
      <DontMiss />
    </div>
  );
}

export default AboutUs;
