"use client";
import React from "react";
import { motion } from "framer-motion";
import { processes } from "./data";
import icon1 from "../../../assets/icons/diamond-brown.svg"
import { useTranslation } from "react-i18next";

// Define the structure of the "processes" data
interface Process {
  id: string;
  title: string;
  description: string;
}

function TheProcess() {

  const { t } = useTranslation();
  const processes: Process[] = t("the_process.processes", { returnObjects: true }) as Process[];

  return (
    <div className="widow-main py-[50px] md:py-[100px] relative z-[2] bg-[#F4F1EC] overflow-hidden">
      <div className="widow-inner">
        <motion.p
          initial={{ x: 100, y: 100, opacity: 0 }}
          whileInView={{ x: 0, y: 0, opacity: 1 }}
          viewport={{ once: true }}
          className="text-6xl font-semibold mb-4 text-center text-[#000000]">
          {t("the_process.title")}
          </motion.p>

        <motion.p
          initial={{ x: 100, y: 100, opacity: 0 }}
          whileInView={{ x: 0, y: 0, opacity: 1 }}
          viewport={{ once: true }}
          className="text-lg font-normal text-center mx-auto mb-12">
          {t("the_process.description")}
          <br />
          {t("the_process.description2")}
        </motion.p>

        <div className="flex flex-col lg:flex-row gap-8 lg:gap-40 items-center justify-center">
          {processes.map((item, index) => (
            <motion.div
              key={item?.id}
              initial={{ x: 100, y: 100, opacity: 0 }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3 * index,
                },
              }}
              viewport={{ once: true }}
              className="w-full sm:w-1/2 lg:w-[373px] flex flex-col items-center mt-8 lg:mt-0 bg-white px-6 py-8 rounded-lg shadow-lg">
              <img
                src={icon1}
                alt={item?.title}
                className="mx-auto h-40"
              />
              <div className="mt-4 lg:mt-6 text-center">
                <p className="text-xl font-semibold text-[#161616] mb-2 uppercase">
                  {item?.title}
                </p>
                <p className="text-base font-normal text-[#5C5C5C]">
                  {item?.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TheProcess;
