import React from "react";
import "./style.css";
import image from "../../../../assets/images/get-started/get-started-banner.webp"
import icon from "../../../../assets/icons/logo-gold.svg"

function ComprehensiveCarousel({
  emblaRef,
  slides = [],
  selectedIndex,
}: {
  emblaRef?: any;
  slides?: any;
  selectedIndex?: any;
}) {

    
  return (
    <section className=" m-auto">
      <div className="overflow-hidden" ref={emblaRef}>
        <div
          className="  embla__container"
          style={{
            backfaceVisibility: "hidden",
          }}
        >
          {slides.map((item?: any, index?: any) => (
            <div key={item?.id} className="min-w-[100%] ">
              <div
                className={`  bg-white h-auto md:w-[80%] w-[100%] mx-auto items-center flex flex-col md:flex-row  gap-[32px] xl:gap-[40px]`}
              >
                <div className="bg-[#094C3B] w-full min-h-[500px] rounded-[16px] relative overflow-hidden sm:p-[20px] lg:p-[70px]">
                  <img
                    src={image}
                    className="w-full h-full object-cover left-0 top-0 absolute z-[1]"
                  />

                  <div className="w-full h-full  z-[2] relative p-2">
                    <div className="w-full h-full flex flex-col gap-[40px] lg:gap-[60px] items-center justify-between">
                      <img src={icon} className="w-[87px]" />
                      <p className="text_34 font-[600] text-[#CDB972] text-center">
                        {item.id}. {item.title}
                      </p>

                      
                      <p className="text-white font-jost text-lg text-center">
                        {item.descriptionKey}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ComprehensiveCarousel;
