import React from "react";
import "./style.css"; // Assuming you have a separate CSS file for styling

interface CustomCSSProperties extends React.CSSProperties {
  "--i"?: number;
}

function Loader() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999999]">
      <figure className="loader">
        {[...Array(12)].map((_, index) => (
          <div
            key={index}
            style={{ "--i": index + 1 } as CustomCSSProperties}
          ></div>
        ))}
      </figure>
    </div>
  );
}

export default Loader;
