import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Dropdown from "./dropdown";
import useWindowSize from "../hooks/useWindowSize";
import { Link, useLocation } from "react-router-dom";
import GeoTranslate from "../molecules/geoTranslate";
import menuClose from "../../assets/icons/close-white.svg";
import menuOpen from "../../assets/icons/menu-white.svg";
import whiteLogo from "../../assets/icons/logo-white.svg";
import textLogoWhite from "../../assets/icons/logo-text-white.svg";
import penLogo from "../../assets/icons/pen-white.svg";
import { useTranslation } from "react-i18next";

function NavBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const isContactPage = location.pathname === "/contact-us";
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(!showMenu);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsTop(scrollTop < 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { sm } = useWindowSize();

  return (
    <div
      className={`${
        isContactPage || !isTop ? "h-[80px] pt-[15px]" : "h-[275px] pt-[20px] sm:pt-[30px]"
      } ${
        !isTop ? "fixed z-[10]" : "relative"
      } widow-main max-w-[100vw] bg-[#161616] pb-[30px] transition-all duration-500 ease-in-out`}
    >
      <AnimatePresence>
        {showMenu ? (
          <motion.div
            initial={{ scale: 0, rotate: "280deg", opacity: 0 }}
            animate={{ scale: 1, rotate: "0deg", opacity: 1 }}
            exit={{ scale: 0, rotate: "280deg", opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="py-[20px] w-full bg-[#161616] absolute left-0 md:h-[100vh] h-[100vh] top-[80px] sm:h-[calc(100vh-80px)] z-[12] font-[600]"
          >
            <Dropdown handleClose={() => setShowMenu(false)} />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <div className="widow-inner">
        <div className={`flex items-center justify-between w-full`}>
          <motion.button
            onClick={openMenu}
            whileHover="animate"
            className="text-white flex items-center gap-3 cursor-pointer relative z-[11]"
          >
            <motion.span className="text-inherit">{t("navbar.menu")}</motion.span>
            {showMenu ? (
              <motion.img
                src={menuClose}
                alt={t("navbar.contact_close")}
                variants={{
                  animate: {
                    scale: 1.2,
                    rotate: "4deg",
                  },
                }}
              />
            ) : (
              <motion.img
                src={menuOpen}
                alt={t("navbar.contact_open")}
                variants={{
                  animate: {
                    scale: 1.2,
                    rotate: "4deg",
                  },
                }}
              />
            )}
          </motion.button>

          <div className="flex flex-col justify-start absolute z-[10] w-full h-full top-0 left-0 pt-[80px] sm:pt-[15px]">
            {isContactPage || !isTop ? null : (
              <AnimatePresence>
                {!showMenu ? (
                  <Link to="/">
                    <motion.img
                      initial={{ opacity: 0, y: "-60%" }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: "-60%" }}
                      transition={{ duration: 0.4 }}
                      src={whiteLogo}
                      alt={t("navbar.logo_alt")}
                      className="cursor-pointer w-[80px] sm:w-[120px] aspect-square mx-auto mb-[29px]"
                    />
                  </Link>
                ) : null}
              </AnimatePresence>
            )}
            {sm && !isTop ? null : (
              <Link to="/">
                <motion.img
                  src={textLogoWhite}
                  alt={t("navbar.logo_alt")}
                  className={`${
                    isContactPage || !isTop
                      ? "md:top-auto -top-16 md:left-auto -left-4"
                      : ""
                  } cursor-pointer w-[150px] sm:w-[274px] h-[50px] md:top-auto mx-auto transition-all duration-500 ease-linear relative z-[11]`}
                />
              </Link>
            )}
          </div>
          <div className="flex gap-4">
            {isContactPage ? (
              <Link to="/">
                <motion.img
                  src={menuClose}
                  alt={t("navbar.contact_close")}
                  className="w-[32px] h-[32px] sm:w-[45px] sm:h-[45px] relative z-[12]"
                  whileHover={{
                    scale: 1.2,
                    rotate: "15deg",
                  }}
                />
              </Link>
            ) : (
              <Link to="/contact-us">
                <motion.button
                  whileHover="animate"
                  whileTap="animate"
                  className="text-white text-[14px] leading-[22px] px-[12px] py-[8px] flex items-center gap-3 cursor-pointer relative z-[13] overflow-hidden rounded-[32px]"
                >
                  <span className="text-inherit relative z-[2]">
                    {t("navbar.lets_talk")}
                  </span>
                  <img src={penLogo} className="relative z-[3]" alt="" />
                  <motion.span
                    variants={{
                      animate: {
                        x: "66%",
                      },
                    }}
                    className="w-full h-full bg-[#094C3B] absolute left-0 top-0 z-[1] rounded-[32px]"
                  ></motion.span>
                </motion.button>
              </Link>
            )}
            <div className="relative z-40">
              <GeoTranslate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
