"use client";
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import leftbutton from "../../assets/icons/circled-arrow-black.svg"
import rightbutton from "../../assets/icons/circled-arrow-black.svg"

const ControlButtons = ({ emblaApi }: { emblaApi?: any }) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi?: any) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="flex flex-1 items-start justify-end gap-[20px]">
      <motion.div
        whileInView="visible"
        initial="hidden"
        viewport={{ once: true }}
        transition={{ delay: 0.3 }}
        variants={{
          visible: { opacity: 1, x: 0, rotate: [0, 180, 270, 360] },
          hidden: { opacity: 0, x: 50 },
        }}>
        <motion.button
          disabled={prevBtnDisabled}
          onClick={onPrevButtonClick}
          className="object-contain flex flex-1 items-start justify-end gap-[16px] disabled:opacity-25"
          whileHover={{
            scale: 1.1,
            transition: { delay: 0, duration: 0.1, ease: "easeInOut" },
          }}>
          <img
            className="w-[50px] h-[50px] object-contain rotate-[180deg]"
            alt="circled-arrow-black"
            src={leftbutton}
          />
        </motion.button>
      </motion.div>
      <motion.div
        whileInView="visible"
        initial="hidden"
        viewport={{ once: true }}
        transition={{ delay: 0.5 }}
        variants={{
          visible: { opacity: 1, x: 0, rotate: [0, 180, 270, 360] },
          hidden: { opacity: 0, x: 50 },
        }}>
        <motion.button
          disabled={nextBtnDisabled}
          onClick={onNextButtonClick}
          whileHover={{
            scale: 1.1,
            transition: { delay: 0, duration: 0.1 },
          }}
          className="flex flex-1 items-start justify-end gap-[16px] disabled:opacity-25">
          <img
            className="w-[50px] h-[50px] object-contain"
            alt="circled-arrow-black"
            src={rightbutton}
          />
        </motion.button>
      </motion.div>
    </div>
  );
};

export default ControlButtons;
