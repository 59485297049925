import React, { Suspense } from "react";
import PageHeaderPrimary from "../molecules/PageHeaderPrimary";
import TheProcess from "../molecules/TheProcess";
import GetStarted from "../molecules/GetStarted";
import WhoWeAre from "../homepage/whoWeAre";
import OurClients from "../homepage/ourClients";
import FAQs from "../homepage/faqs";
import { useTranslation } from "react-i18next";

function Bespoke() {
  const {t} = useTranslation()
  return (
    <div>
      <PageHeaderPrimary title_1={t("dropdown.items.bespoke")} />
      <TheProcess />
      <Suspense fallback={<div>Loading...</div>}>
        <GetStarted />
      </Suspense>
      <WhoWeAre/>
      <OurClients/>
      <FAQs/>
    </div>
  );
}

export default Bespoke;
