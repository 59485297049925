



export const footerLinks = [
  [
    {
      header: true,
      title: "quick_links",
    },
    { title: "home", path: "/" },
    { title: "about_us", path: "/about-us" },
    { title: "bespoke", path: "/bespoke" },
    { title: "collections", path: "/collections" },
    { title: "lab_diamonds", path: "/collections/diamonds" },
    { title: "faqs", path: "/faqs" },
    { title: "contact_us", path: "/contact-us" },
  ],

  [
    {
      header: true,
      title: "collection",
    },
    { title: "emerald", path: "/collections" },
    { title: "pear", path: "/collections" },
    { title: "oval", path: "/collections" },
    { title: "round", path: "/collections" },
    { title: "baguette", path: "/collections" },
    { title: "cushion", path: "/collections" },
    { title: "radiant", path: "/collections" },
  ],

 
];

export default function getAddressByDomain() {
  const domain = window.location.host;

  switch (domain) {
    case 'ecorocksdenia.com':
    case 'ecorocks.es':
      return {
        email: "bespoke@ecorocks.es",
        phone: "+34653169579",
        address: "Calle Marques de Campo 45-1A Cp 03700 Denia"
      };
    case 'ecorocks.co.za':
      return {
        email: "bespoke@ecorocks.co.za",
        phone: "+27828968254",
        address: "South Africa"
      };
    case 'weareecorocks.com':
      return {
        email: "bespoke@ecorocks.es",
        phone: "+34653169579",
        address: "Calle Marques de Campo 45-1A Cp 03700 Denia"
      };
    default:
      return {
        email: "bespoke@ecorocks.es",
        phone: "+34653169579",
        address: "Calle Marques de Campo 45-1A Cp 03700 Denia"
      };; // Default currency if domain doesn't match
  }
}

