"use client";
import React, { useEffect, useState } from "react";
import getCurrencyByDomain from "../data";
import { FaAnglesLeft } from "react-icons/fa6";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleMinus } from "react-icons/ci";
import { GoArrowUpRight } from "react-icons/go";
import ContactInfo from "./contactInfo";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../molecules/loader/loader";
import { useTranslation } from "react-i18next";

interface Image {
  url: string;
  type: string;
}

interface PriceRange {
  priceRange: string;
}

interface ProductMetal {
  _id: number;
  name: string;
}
interface Product {
  _id: string;
  code: string;
  imagesVideos: Image[];
  mainImage: string;
  description: string;
  name: string;
  currencySign: string;
  minPrice: number;
  maxPrice: number;
  finalPrice: number;
  diamondShapes: string;
  details: string;
  shape: string;
  specs: {
    diamond: string;
    metal: ProductMetal[];
    carat: { id: number; name: string; price: number; size?: string }[];
    Cut: string;
    RingSize?: string;
  };
}

interface Size {
  sizeId: number;
  size: string;
  prices: { currency: { sign: string }; price: number }[];
}

interface Metal {
  metalId: number;
  metal: string;
}


function Topsection({ params }: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Translation hook
  const [image, setimage] = useState(0);
  const [price, setPrice] = useState<number | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [detail, setDetail] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [products, setProducts] = useState<Product>();
  const [size, setSize] = useState<Size[]>([]); // Define state type as Size[]
  const [metal, setMetal] = useState<Metal[]>([]); // Define state type as Metal[]
  const [selectedMetal, setSelectedMetal] = useState<string>("");
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [loading, setLoading] = useState(true); // Loader state

  const openModal = () => {
    setModalVisible((prevState) => !prevState);
  };

  
  // Function to fetch products based on filters
  const fetchProducts = async () => {
    setLoading(true); // Start loading
    const response = await fetch(
      `https://api.ecorocksdenia.com/api/product/product/${
        params
      }?&sizeId=${selectedSize}&metalId=${selectedMetal}&currencyCode=${getCurrencyByDomain()}&languageCode=${i18n.language}`
    );
    const data = await response.json();
    setProducts(data?.data?.details); // Assuming the response data is an array of products
    const uniqueSize = Array.from(
      new Set(data?.data?.variants.map((item: any) => item.size))
    ).map((size) =>
      data?.data?.variants.find((item: any) => item.size === size)
    );
    const uniqueMetal = Array.from(
      new Set(data?.data?.variants.map((item: any) => item.metal))
    ).map((metal) =>
      data?.data?.variants.find((item: any) => item.metal === metal)
    );

    setSize(uniqueSize);
    setMetal(uniqueMetal);
    setLoading(false); // End loading
  };

  useEffect(() => {
    fetchProducts();
  }, [selectedSize, selectedMetal, , i18n.language]);


  const details = [
    {
      name: "Description",
      value: 
        products ? (
          <div dangerouslySetInnerHTML={{ __html: products.description }} style={{ maxHeight: "100px", overflowX: "hidden" }} />
      ) : null
      ,
    },
    {
      name: "Specs",
      value: (
        <div>
          <div className="flex gap-2.5 text-black">
            <span className="text-[#CDB972] min-w-20 font-medium ">
              Diamond:
            </span>
            {products?.shape}
          </div>
          <div className="flex gap-2.5 text-black">
            <span className="text-[#CDB972] min-w-20 font-medium ">Metal:</span>
            {metal?.map((item) => item.metal + ", ")}
          </div>
          <div className="flex gap-2.5 text-black">
            <span className="text-[#CDB972] min-w-20 font-medium ">Carat:</span>
            {size?.map((item) => item.size + ", ")}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="widow-main py-[50px] md:py-[100px] bg-[#F4F1EC]">
      <ContactInfo
        isToggled={modalVisible}
        setToggled={openModal}
        metal={metal}
        ringCode={products?.code}
        ringSize={size}
        quantity={quantity}
      />
      <div className="widow-inner grid md:grid-cols-2 grid-cols-1 gap-14 ">
        {loading && <Loader />}
        <>
          <div className="flex md:flex-row flex-col gap-5">
            <button
              onClick={() => navigate("/collections")}
              className="button hover:bg-[#094C3B] md:hidden flex text-black hover:text-white border-[#161616]"
            >
              <FaAnglesLeft className="my-auto" />
              {t("filters.backToCollection")}
            </button>
            <div className="flex md:flex-col flex-wrap gap-5 md:order-1 order-2">
              {products?.imagesVideos?.map((item: any, index: any) =>
                item.type === "image" ? (
                  <div
                    className={`${
                      index === image ? "border border-[#094C3B]" : ""
                    } h-[60px]  w-[50px] p-1`}
                    onClick={() => setimage(index)}
                    key={index}
                  >
                    <img src={item.url} alt="" className="h-full bg-white" />
                  </div>
                ) : (
                  <div
                    className={`${
                      index === image ? "border border-[#094C3B]" : ""
                    } h-[60px]  w-[50px] p-1`}
                    onClick={() => setimage(index)}
                    key={index}
                  >
                    <video
                      id="videoPlayer"
                      className="w-full h-full object-cover"
                      playsInline
                      autoPlay
                      preload="none"
                      loop
                      muted
                    >
                      <source src={item.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )
              )}
            </div>
            <div className="max-h-[653px] max-w-[508px] bg-white md:order-2 order-1">
              {products?.imagesVideos?.length  &&
              products?.imagesVideos[image].type === "image" ? (
                <img
                  src={products?.imagesVideos[image].url}
                  alt=""
                  className="h-full w-full object-contain"
                />
              ) : (
                <video
                  id="videoPlayer"
                  className="h-full w-full bg-white border-none object-contain"
                  playsInline
                  autoPlay
                  preload="none"
                  loop
                  muted
                >
                  <source
                    src={
                      products?.imagesVideos?.length && products?.imagesVideos?.length >0 ?
                      products?.imagesVideos[image].url : ""
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-8">
              <button
                onClick={() => navigate("/collections")}
                className="button hover:bg-[#094C3B] md:flex hidden text-black hover:text-white border-[#161616]"
              >
                <FaAnglesLeft className="my-auto" />
                {t("filters.backToCollection")}
              </button>
              <div className="font-jost">
                <div className="text-4xl text-[#222222] font-semibold ">
                  {products?.name} - {products?.code}
                </div>
                <div className="text-[#222222] text-lg mt-3">
                  {products?.finalPrice !== null
                    ? `${products?.currencySign}${products?.finalPrice}`
                    : `${products?.currencySign}${products?.minPrice} - ${products?.currencySign}${products.maxPrice}`}
                </div>
              </div>
              <div className="text-[#5C5C5C] font-jost">{products?.details}</div>
            </div>

            <div className="grid grid-rows-3 gap-2">
              <div className="flex justify-between py-4 border-b border-b-[#D0D0D0]">
                <div className="uppercase">{t("filters.metal")}</div>
                <div>
                  <select
                    name="metal"
                    value={selectedMetal}
                    onChange={(e) => setSelectedMetal(e.target.value)}
                    className="bg-[#F4F1EC] outline-none md:min-w-[210px] text-sm"
                  >
                    <option value="">{t("filters.options")}</option>
                    {metal.map((item: any, index: number) => (
                      <option key={index} value={item.metalId}>
                        {item.metal}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex justify-between py-4 border-b border-b-[#D0D0D0]">
              <div className="uppercase">{t("filters.stoneSize")}</div>
              <div>
                  <select
                    name="size"
                    className="bg-[#F4F1EC] outline-none md:min-w-[210px] md:max-w-full text-sm"
                    onChange={(e) => setSelectedSize(e.target.value)}
                    value={selectedSize}
                  >
                    <option value="">{t("filters.options")}</option>
                    {size.map((item: any, index: number) => (
                      <option
                        key={index}
                        value={item.sizeId}
                        className="whitespace-break-spaces"
                      >
                        {item.size} -  {products?.currencySign}{item?.prices?.filter((price: any)=> price?.currency.sign === products?.currencySign)[0]?.price}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex justify-between py-4 border-b border-b-[#D0D0D0]">
              <div className="uppercase">{t("filters.quantity")}</div>
                <div className="flex items-center gap-3">
                  <button
                    className="border border-[#D0D0D0] p-1 rounded-full"
                    onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
                  >
                    <CiCircleMinus />
                  </button>
                  <span className="text-lg">{quantity}</span>
                  <button
                    className="border border-[#D0D0D0] p-1 rounded-full"
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    <IoIosAddCircleOutline />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="flex ">
                {details?.map((item: any, index: number) => (
                  <div
                    className={`${
                      index === detail
                        ? "bg-white text-black font-medium"
                        : null
                    } text-[#B9B9B9] font-jost px-4 py-2.5 cursor-pointer`}
                    onClick={() => setDetail(index)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="bg-white p-4">{details[`${detail}`].value}</div>
            </div>
            <div className="flex md:gap-10 gap-2 font-jost">
              <button
                onClick={openModal}
                // href={`/bespoke?redirect=true&shape=${data?.diamondShapes}#getStart`}
                className=" button hover:bg-white bg-[#094C3B] hover:text-black text-white border-[#094C3B] w-full "
              >
                {t("filters.proceed")}
                <GoArrowUpRight className="text-xl my-auto" />
              </button>
              <Link
                to={"/contact-us"}
                className=" button border-[#CDB972] hover:bg-[#CDB972] w-full  text-[#CDB972] hover:text-white "
              >
                {t("filters.contactUs")}
                <GoArrowUpRight className="text-xl my-auto" />
              </Link>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default Topsection;
