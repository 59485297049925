import React from 'react'
import PageHeaderPrimary from '../molecules/PageHeaderPrimary'
import FAQs from '../homepage/faqs'
import DontMiss from '../homepage/dontMiss'
import DiamondEducationGuide from './diamond-education-guide'
import { useTranslation } from 'react-i18next'

function Education() {
  const {t} = useTranslation()

  return (
    <div>
              <PageHeaderPrimary title_1={t("dropdown.items.education")}  />
              <DiamondEducationGuide />
              <FAQs/>
              <DontMiss />
    </div>
  )
}

export default Education
