import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTrashAlt } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { LuUploadCloud } from "react-icons/lu";

function GetStartedStep2({ setFieldValue }: any) {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleFileChange = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const selectedFile = files[0];

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("upload_preset", "vyau5eye"); // Replace with your actual upload preset

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dyl5esjes/image/upload`, // Replace with your Cloudinary cloud name
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (response.ok) {
        setFieldValue("fileUrl", data.secure_url);
        setFileName(data.original_filename);
      } else {
        throw new Error(data.error.message);
      }
    } catch (error: any) {
      setUploadError(error.message);
      console.error("Upload error:", error);
    }
  };

  const deleteFile = () => {
    setFileName(null);
    setFieldValue("fileUrl", "");
    setUploadError(null);
    setImageUrl(null);
  };

  return (
    <div className="mt-20 flex flex-col gap-10">
      <p className="text_34 font-[600] text-[#CDB972] text-center">
        {t("getStarted.step3title")}
      </p>
      <div className="px-[30px] md:w-2/3 w-full mx-auto py-5 flex flex-col gap-3 items-center justify-center border border-white rounded-[8px] hover:bg-white/35 bg-white/20 text-white">
        {fileName ? (
          <div className="flex gap-4">
            {fileName}
            <FaTrashAlt
              aria-label="delete"
              onClick={() => deleteFile()}
              className="text-red-600 my-auto"
            />
          </div>
        ) : (
          <label
            htmlFor="file"
            className="flex flex-col gap-3 items-center justify-center"
          >
            <div className="rounded-full border-2 p-2 text-xl border-[#CFD4D9] w-fit">
              <LuUploadCloud />
            </div>
            <div className="text-white flex gap-2">
             {t("getStarted.drd")}{" "}
              <div className="text-[#CDB972] font-semibold">{t("getStarted.ChooseFiles")}</div>
              <input
                type="file"
                name="file"
                id="file"
                className="sr-only"
                onChange={(e) => handleFileChange(e.target.files)}
              />
            </div>
          </label>
        )}
      </div>
      {uploadError && <p className="text-red-600">{uploadError}</p>}
      <p className="text-[#D0D0D0] font-jost text-lg m-auto mb-10">
        {t("getStarted.step3note")}{" "}
      </p>
    </div>
  );
}

export default GetStartedStep2;
