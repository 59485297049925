import React from "react";
import DontMiss from "../../homepage/dontMiss";
import HomepageProducts from "../../homepage/homepageProducts";
import { useParams } from "react-router-dom";
import Topsection from "./topsection";
import PageHeaderSecondary from "../../molecules/PageHeaderSecondary";
import { useTranslation } from "react-i18next";

function Productdetails() {
  const { id } = useParams();
  const {t} = useTranslation()

  return (
    <div>
      <PageHeaderSecondary title_1={t("dropdown.items.collections")} />

      <Topsection params={id} />
      <HomepageProducts />
      <DontMiss />
    </div>
  );
}

export default Productdetails;
