// App.js
import React, { ReactNode, useLayoutEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./i18n"; // Ensure this import is present
import Layout from "./components/layout/layout";
import HomePage from "./components/homepage/homepage";
import AboutUs from "./components/aboutUs/aboutUs";
import Bespoke from "./components/bespoke/bespoke";
import Collection from "./components/collection/collection";
import Productdetails from "./components/collection/details/productdetails";
import ContactUs from "./components/contact-us/contactUs";
import Education from "./components/education/education";
import LabDiamonds from "./components/lab-diamonds/labDiamonds";
import PrivacyPolicy from "./components/privacy-policy/privacyPolicy";
import Faqs from "./components/faqs/faqs";
import ThankYou from "./components/thankYou/thankYou";
import TermsAndConditions from "./components/terms-and-conditions/TermsAndConditions";

function App() {
  const Wrapper = ({ children }: { children: ReactNode }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      // Scroll to the top of the page when the route changes
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }, [location.pathname]);

    return <>{children}</>;
  };
  
  return (
    <div className="">

    <Router>
    <Wrapper>

      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<HomePage />} />
          <Route index path="/about-us" element={<AboutUs />} />
          <Route index path="/bespoke" element={<Bespoke />} />
          <Route index path="/collections" element={<Collection />} />
          <Route index path="/collections/collections/:id" element={<Productdetails />} />
          <Route index path="/contact-us" element={<ContactUs />} />
          <Route index path="/education" element={<Education />} />
          <Route index path="/collections/diamonds" element={<LabDiamonds />} />
          <Route index path="/labDiamonds" element={<LabDiamonds />} />
          <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route index path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route index path="/faqs" element={<Faqs />} />
          
        </Route>
          <Route index path="/thankYou" element={<ThankYou />} />
      </Routes>
      <Toaster
        containerStyle={{
          margin: "20px 0 0 - 250px",
          padding: "0 16px",
          height: "40px",
          zIndex: 9999999,
        }}
        toastOptions={{
          success: {
            style: {
              padding: "16px",
              // width: "500vw",
            },
          },
          loading: {
            style: {
              padding: "16px",
            },
          },
          error: {
            style: {
              background: "#f87171",
              padding: "16px",
              color: "#fff",
              // width: "500vw",
            },
          },
        }}
        />
        </Wrapper>
    </Router>
        </div>
  );
}

export default App;
