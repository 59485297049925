"use client";
import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import ControlButtons from "../homepage/ControlButtons";
import ComprehensiveCarousel from "../molecules/lab-diamond/components/comprehensive-carousel";
import { useTranslation } from "react-i18next";

const options = { startIndex: 0 };


function DiamondEducationGuide() {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      title: t("4cs.title"),
      descriptionKey:
      t("4cs.description")
      ,
    },
    {
      id: 2,
      title: t("DiamondCut.title"),
      descriptionKey: (
        <div className="flex flex-col gap-5 text-center">
          <div className="flex flex-col gap-5 list-disc text-center">
            <div className="text-white">
              {t("DiamondCut.description1")}
            </div>
            <div className="text-white">
              {t("DiamondCut.description2")}
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      title: t("DiamondColour.title"),
      descriptionKey: (
        <div className="text-start flex flex-col gap-5 text-center">
          <div className="flex flex-col gap-5 list-disc text-center">
            <div className="text-white">
              {t("DiamondColour.description1")}
            </div>
            <div className="text-white">
              {t("DiamondColour.description2")}
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      title: t("DiamondClarity.title"),
      descriptionKey: (
        <div className="text-start flex flex-col gap-5 text-center">
          <div className="flex flex-col gap-5 list-disc text-center">
            <div className="text-white">
              {t("DiamondClarity.description1")}
            </div>
          </div>
  
          <ul className="flex flex-col gap-5 list-disc">
            <li className="text-white">
              <b>FL</b> – {t("DiamondClarity.FL")}
            </li>
            <li className="text-white">
              <b>IF</b> – {t("DiamondClarity.IF")}
            </li>
            <li className="text-white">
              <b>VVS1 / VVS2</b> – {t("DiamondClarity.VVS")}
            </li>
            <li className="text-white">
              <b>VS1 / VS2</b> – {t("DiamondClarity.VS")}
            </li>
            <li className="text-white">
              <b>SI1 / SI2</b> – {t("DiamondClarity.SI")}
            </li>
            <li className="text-white">
              <b>I1, I2, I3</b> – {t("DiamondClarity.I")}
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 5,
      title: t("CaratWeight.title"),
      descriptionKey: (
        <div className="text-start flex flex-col gap-5 text-center">
          <div className="flex flex-col gap-5 list-disc text-center">
            <div className="text-white">
              {t("CaratWeight.description")}
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 6,
      title: t("ConclusionWhyEcoRocks.title"),
      descriptionKey: (
        <div className="text-start flex flex-col gap-5 text-center">
          <div className="flex flex-col gap-5 list-disc text-center">
            <div className="text-white">
              {t("ConclusionWhyEcoRocks.description1")}
            </div>
            <div className="text-white">
              {t("ConclusionWhyEcoRocks.description2")}
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-[#f4f1ec] widow-main py-[50px] md:py-[100px]">
      <div className="widow-inner">
        <div className="flex justify-between items-center mb-[32px] sm:mb-[60px]">
          <h1 className="md:text_60 text-3xl text-[#161616]">
            {t("DiamondEducation.title")}
          </h1>
          <div className="">
            <ControlButtons emblaApi={emblaApi} />
          </div>
        </div>
      </div>
      <ComprehensiveCarousel slides={data} emblaRef={emblaRef} />
    </div>
  );
}

export default DiamondEducationGuide;
