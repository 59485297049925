import React from 'react'
import { useTranslation } from 'react-i18next'

function FinalStep({setStep, loading}: {setStep: any, loading:boolean}) {
  const {t} = useTranslation()
  return (
    <div className="w-full h-full flex flex-col gap-[40px] lg:gap-[60px] mt-20 items-center justify-center">
       <p className="text_34 font-[600] text-[#CDB972] text-center">
       {t("getStarted.finalWord")}
      </p>
      <button type='submit' disabled={loading} className="bg-[#FFFFFFCC] rounded-md font-semibold text-[#094C3B] p-4 min-w-44 "> 
        {t("getStarted.submitButton")}
      </button>
    </div>
  )
}

export default FinalStep
