"use client";
import React from "react";
import { motion } from "framer-motion";
import image1 from "../../assets/images/who-we-are/who-we-are-box-frame-1.webp";
import image2 from "../../assets/images/who-we-are/who-we-are-box-frame-2.webp";
import image3 from "../../assets/images/home-page/homepage-wer1.jpg";
import image4 from "../../assets/images/home-page/homepage-wer2.jpg";
import { useTranslation } from "react-i18next";

function WhoWeAre() {
  const { t } = useTranslation();
  return (
    <div className="widow-main py-[50px] md:py-[100px] bg-[#161616] max-w-full overflow-hidden">
      <div className="widow-inner">
        <div className="flex gap-[24px] flex-col lg:flex-row w-full">
          <motion.div
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="flex h-[300px] lg:h-[280px] w-full lg:max-w-[384px] rounded-[16px] overflow-hidden bg-[#094C3B] relative py-[30px] px-[16px] sm:px-[30px]"
          >
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={image1}
            />
            <h3 className="text_48 relative z-[1] text-white mt-auto">
              {t("whoWeAre.title")}
            </h3>
          </motion.div>
          <motion.div
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="flex h-[300px] lg:h-[280px] w-full rounded-[16px] overflow-hidden bg-[#F4F1EC] relative py-[30px] px-[16px] sm:px-[30px]"
          >
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={image2}
            />
            <div className="relative z-[1] mt-auto max-w-[431px]">
              <h3 className="text-3xl whitespace-nowrap text-[#161616] font-[600]">
                {t("whoWeAre.card2title")}{" "}
              </h3>
              <p className="text_16 text-[#161616] mt-[10px]">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("whoWeAre.description"),
                  }}
                />
              </p>
            </div>
          </motion.div>
        </div>

        <div className="grid lg:grid-cols-2  gap-[24px]  mt-[24px]">
          <motion.div
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="flex h-[300px] lg:h-[280px] w-full rounded-[16px] overflow-hidden bg-white relative "
          >
            <img
              className="absolute top-0 left-0 w-full h-full md:p-5 object-contain"
              src={image3}
            />
          </motion.div>

          <motion.div
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="flex h-[300px] lg:h-[280px] w-full  rounded-[16px]  overflow-hidden bg-white relative py-[30px] px-[16px] sm:px-[30px]"
          >
            <img
              className="absolute top-0 left-0 w-full h-full object-contain "
              src={image4}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
