import React from "react";
import { useTranslation } from "react-i18next";

function HowItWork() {
  const { t } = useTranslation();

  return (
    <div className="bg-[#f4f1ec] widow-main py-[50px] md:py-[100px]">
      <div className="widow-inner">
        <p className="font-jost text-[#191A37] font-[500]">
          {t("HowItWork.title")}
        </p>
        <div className="grid grid-cols-2 md:gap-20 gap-5 mt-6">
          <div className="md:text-6xl text-5xl font-jost md:col-span-1 col-span-2">
            {t("HowItWork.heading")}
          </div>
          <div className="text-[#2D2D2D] md:col-span-1 col-span-2">
            {t("HowItWork.description")}
          </div>
          <div className="w-full col-span-2">
            <video
              id="videoPlayer"
              className="w-full h-[544px] object-cover rounded-3xl"
              playsInline
              autoPlay
              preload="none"
              loop
              muted
            >
              <source src="https://res.cloudinary.com/dsn0wxtly/video/upload/v1731082211/Ring_s0qff5.mp4" type="video/mp4" />
              {t("HowItWork.videoFallback")}
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWork;
