"use client";
import useEmblaCarousel from "embla-carousel-react";
import ComprehensiveCarousel from "../molecules/lab-diamond/components/comprehensive-carousel";
import ControlButtons from "../homepage/ControlButtons";
import { useTranslation } from "react-i18next";

const options = { startIndex: 0 };

function ComprehensiveGuide() {
  const { t } = useTranslation();
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const data = [
    {
      id: 1,
      title: t("labDiamond.sliderTitle1"),
      descriptionKey: t("labDiamond.sliderDescription1"),
    },
    {
      id: 2,
      title: t("labDiamond.sliderTitle2"),
      descriptionKey: (
        <div className="text-start flex flex-col gap-5 text-center">
          <p className="text-white text-center">
            {t("labDiamond.sliderDescription2.part1")}
          </p>
          <ul className="flex flex-col gap-5 list-disc text-center">
            <li className="text-white">
              {t("labDiamond.sliderDescription2.part2")}
            </li>
            <li className="text-white">
              {t("labDiamond.sliderDescription2.part3")}
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      title: t("labDiamond.sliderTitle3"),
      descriptionKey: t("labDiamond.sliderDescription3"),
    },
    {
      id: 4,
      title: t("labDiamond.sliderTitle4"),
      descriptionKey: t("labDiamond.sliderDescription4"),
    },
    {
      id: 5,
      title: t("labDiamond.sliderTitle5"),
      descriptionKey: t("labDiamond.sliderDescription5"),
    },
    {
      id: 6,
      title: t("labDiamond.sliderTitle6"),
      descriptionKey: (
        <div className="flex flex-col gap-5 text-center">
          <p className="text-white text-center">
            {t("labDiamond.sliderDescription6.part1")}
          </p>

          <p className="text-white">
            {t("labDiamond.sliderDescription6.part2")}
          </p>
        </div>
      ),
    },
  ];
  return (
    <div className=" bg-[#f4f1ec] widow-main py-[50px] md:py-[100px]">
      <div className="widow-inner">
        <div className="flex justify-between items-center mb-[32px] sm:mb-[60px]">
          <h1 className="md:text_60 text-3xl text-[#161616]">
            {t("comprehensiveGuide")}
          </h1>
          <div className="">
            <ControlButtons emblaApi={emblaApi} />
          </div>
        </div>
      </div>
      <ComprehensiveCarousel slides={data} emblaRef={emblaRef} />
    </div>
  );
}

export default ComprehensiveGuide;
