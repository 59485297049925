import { ErrorMessage, Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function GetStartedStep3({ errors }: any) {
  const {t} = useTranslation()

  return (
    <div className="mt-20 flex flex-col gap-10">
      <p className="text_34 font-[600] text-[#CDB972] text-center ">
        {t("getStarted.step5title")}
      </p>
      <div className="grid md:grid-cols-3 gap-6 w-3/4 mx-auto">
        <div>
          <Field
            type="text"
            name="legalName"
            placeholder={t("getStarted.formLabels.legalName")}
            className=" h-16 px-4 border border-white rounded-[8px] w-full hover:bg-white/35 bg-white/20 text-white "
          />
          {errors?.legalName && <div className="text-red-300">{errors?.legalName}</div>}
        </div>
        <div>
          <div>

          <Field
            type="email"
            name="email"
            placeholder={t("getStarted.formLabels.email")}
            className=" h-16 px-4 border border-white rounded-[8px] w-full hover:bg-white/35 bg-white/20 text-white "
            />
            {errors?.email && <div className="text-red-300">{errors?.email}</div>}
            </div>
        </div>
        <div>
          <div>

          <Field
            type="tel"
            name="phoneNumber"
            placeholder={t("getStarted.formLabels.phoneNumber")}
            className=" h-16 px-4 border border-white rounded-[8px] w-full hover:bg-white/35 bg-white/20 text-white "
            />
            {errors?.phoneNumber && <div className="text-red-300">{errors?.phoneNumber}</div>}
            </div>
        </div>
      </div>
      <p className="text-[#D0D0D0] font-jost text-lg m-auto mb-10 md:w-2/3 w-full text-center mt-5">
       {t("getStarted.step5note")}
      </p>
    </div>
  );
}

export default GetStartedStep3;
