"use client";
import React from "react";
import style from "./header.module.css";
import { motion } from "framer-motion";

function PageHeaderSecondary({ title_1 = "" }: { title_1?: string }) {
  return (
    <div className="widow-main bg-[#161616]  relative overflow-hidden">
      <div className="widow-inner pt-[40px] sm:pt-[80px] pb-[100px] sm:pb-[300px] max-h-[800px] overflow-hidden  ">
        <div className="absolute left-[0]  bottom-[-50px] 2xl:bottom-[50] w-full flex justify-center">
          <motion.img
            initial={{ x: -250, y: 250, opacity: 0, rotate: "90deg" }}
            whileInView={{ x: 0, y: 0, opacity: 0.7, rotate: 0 }}
            transition={{ duration: 1.3, delay: 1 }}
            viewport={{ once: true }}
            className=" w-[600px] "
            src="/images/home-page/homepage-header-middle.webp"
          />
        </div>

        <h1 className={`${style["text-effect"]} text_96 relative z-[3]`}>
          {title_1}
        </h1>
      </div>
    </div>
  );
}

export default PageHeaderSecondary;
