"use client";
import React from "react";
import { motion } from "framer-motion";
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";
import ImageGrid from "../molecules/ImageGrid";
import imageBottom from "../../assets/images/home-page/homepage-aboutUs-3.svg";
import imageTop from "../../assets/images/home-page/homepage-aboutUs-2.svg";
import imageLeft from "../../assets/images/home-page/homepage-aboutUs-1.svg";
import { useTranslation } from "react-i18next";

function HomepageAbout() {
  const { t } = useTranslation();

  return (
    <div className="widow-main py-[50px] md:py-[100px] relative z-[1] bg-white md:mt-0 mt-20 overflow-hidden">
      <div className="widow-inner flex flex-col md:flex-row items-start justify-between gap-[24px] xl:gap-[32px]">
        <ImageGrid
          imageBottom={imageBottom}
          imageTop={imageTop}
          imageLeft={imageLeft}
        />

        <div className="w-full max-w-[560px]">
          <motion.p
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="text_24 text-[#CDB972] font-[500]"
          >
            {t("dropdown.items.about_us")}
          </motion.p>
          <motion.p
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="md:text-6xl text-4xl text-[#161616] font-[500] mt-[24px] xl:mt-[32px]"
          >
            <div className="whitespace-nowrap">{t("about.discover_section.Personalised_Service")},</div>
            <div className="whitespace-nowrap">{t("about.discover_section.Exceptional_Value")},</div>
            <div className="whitespace-nowrap">{t("about.discover_section.Ethically_Friendly")}</div>
          
          </motion.p>

          <motion.p
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="text_20 text-[#2D2D2D] font-[400] my-[24px] xl:my-[32px]"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t("about.discover_section.intro"),
              }}
            />
          </motion.p>
          <motion.div
            initial={{ x: 100, y: 100, opacity: 0 }}
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            viewport={{ once: true }}
          >
            <Link to={"/about-us"}>
              <div className="button flex items-center hover:bg-[#094C3B] text-black hover:text-white border-[#161616]">
                {t("homepage.learn")}
                <GoArrowUpRight className="text-xl ml-2" />
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default HomepageAbout;
