import React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsAndConditions.css';

function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <div className="widow-main bg-[#ffffff]">
      <div className="widow-inner py-[100px] sm:py-[150px]">
        <h1>{t('termsAndConditions.title')}</h1>
        <h3>{t('termsAndConditions.promises')}</h3>
        <p>{t('termsAndConditions.description1')}</p>

        <h3>{t('termsAndConditions.item.title1')}</h3>
        <p>{t('termsAndConditions.item.description1')}</p>
        <ul>
          <li>{t('termsAndConditions.item.list1.list1')}</li>
          <li>{t('termsAndConditions.item.list1.list2')}</li>
          <li>{t('termsAndConditions.item.list1.list3')}</li>
          <li>{t('termsAndConditions.item.list1.list4')}</li>
        </ul>

        <p>{t('termsAndConditions.item.description12')}</p>

        <h3>{t('termsAndConditions.item.title2')}</h3>
        <p>{t('termsAndConditions.item.description2')}</p>
        <ul>
          <li>{t('termsAndConditions.item.list2.list1')}</li>
          <li>{t('termsAndConditions.item.list2.list2')}</li>
          <li>{t('termsAndConditions.item.list2.list3')}</li>
          <li>{t('termsAndConditions.item.list2.list4')}</li>
          <li>{t('termsAndConditions.item.list2.list5')}</li>
          <li>{t('termsAndConditions.item.list2.list6')}</li>
          <li>{t('termsAndConditions.item.list2.list7')}</li>
          <li>{t('termsAndConditions.item.list2.list8')}</li>
          <li>{t('termsAndConditions.item.list2.list9')}</li>
        </ul>

        <p>{t('termsAndConditions.item.description22')}</p>

        <h3>{t('termsAndConditions.title3')}</h3>
        <p>{t('termsAndConditions.description3')}</p>

        <h3>{t('termsAndConditions.title4')}</h3>
        <p>{t('termsAndConditions.description4')}</p>
        <ul>
          <li>{t('termsAndConditions.list4.list1')}</li>
          <li>{t('termsAndConditions.list4.list2')}</li>
          <li>{t('termsAndConditions.list4.list3')}</li>
        </ul>

        <p>{t('termsAndConditions.description42')}</p>
        <p>{t('termsAndConditions.description43')}</p>

        <h3>{t('termsAndConditions.title5')}</h3>
        <p>{t('termsAndConditions.description5')}</p>

        <p>{t('termsAndConditions.description52')}</p>
        <ul>
          <li>{t('termsAndConditions.list5.list1')}</li>
          <li>{t('termsAndConditions.list5.list2')}</li>
          <li>{t('termsAndConditions.list5.list3')}</li>
        </ul>

        <p>{t('termsAndConditions.description53')}</p>

        <h3>{t('termsAndConditions.title6')}</h3>
        <p>{t('termsAndConditions.description6')}</p>

        <h3>{t('termsAndConditions.title7')}</h3>
        <p>{t('termsAndConditions.description7')}</p>
        <ul>
          <li>{t('termsAndConditions.list7.list1')}</li>
        </ul>

        <h3>{t('termsAndConditions.title8')}</h3>
        <p>{t('termsAndConditions.description8')}</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
