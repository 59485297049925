import React from "react";
import { diamond_shapes } from "../../../homepage/data";
import { useTranslation } from "react-i18next";

function StepShape({ proceed, setStep }: any) {
  const {t} = useTranslation()
  return (
    <div className="w-full h-full flex flex-col gap-[40px] lg:gap-[60px] items-center justify-center">
      <p className="text_34 mt-20 font-[600] text-[#CDB972] text-center">
        {t("getStarted.step2title")}
      </p>

      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-[24px] w-full justify-center">
        {diamond_shapes.map((x?: any) => (
          <Button
            key={x?.id}
            title={t(`getStarted.${x?.name}`)}
            onClick={() => {
              proceed("shape", x?.name);
              setStep(2);
            }}
          />
        ))}
      </div>
      {/* <p className="text-[#D0D0D0] font-jost text-lg">
    Note: All our diamonds are lab grown diamonds
    </p> */}
    </div>
  );
}

export default StepShape;
const Button = ({ title, onClick }: { title?: any; onClick?: any }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="h-[64px] px-[30px] lg:px-[60px] flex items-center justify-center border border-white rounded-[8px] hover:bg-white/35 bg-white/20 text_16 text-white "
    >
      <span className="text-inherit capitalize">{title}</span>
    </button>
  );
};
