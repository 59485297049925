import React, { useEffect } from "react";
import { motion, useIsPresent } from "framer-motion";
import { Link } from "react-router-dom";
import image from "../../assets/icons/eco-rocks-white.svg";
import { useTranslation } from "react-i18next";

const items = [
  { titleKey: "home", path: "/" },
  { titleKey: "about_us", path: "/about-us" },
  { titleKey: "bespoke", path: "/bespoke" },
  { titleKey: "lab_diamonds", path: "/collections/diamonds" },
  { titleKey: "collections", path: "/collections" },
  { titleKey: "education", path: "/education" },
  { titleKey: "faqs", path: "/faqs" },
];

const useEscapeKeyPress = (callback: any) => {
  const handleKeyDown = (event: any) => {
    if (event.key === "Escape") {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
};

function Dropdown({ handleClose = () => null }) {
  const isPresent = useIsPresent();
  const { t } = useTranslation();

  useEscapeKeyPress(handleClose);

  if (!isPresent) return null;

  return (
    <div className="widow-main h-full lg:h-auto relative z-[15]">
      <div className="widow-inner h-full lg:h-auto flex justify-center lg:justify-between relative z-[16]">
        <motion.div className="flex flex-col justify-start sm:justify-center lg:justify-between gap-y-[1px] sm:gap-y-[20px] relative z-[17] ">
          {items.map((item, index) => (
            <Link
              onClick={handleClose}
              to={item.path}
              key={item.titleKey}
              className="text-[28px] sm:text-[34px] leading-[62px] text-center lg:text-left text-[#CDB972] hover:text-white"
            >
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 100, opacity: 0 }}
                transition={{ duration: 0.2, delay: 0.3 * index }}
              >
                {t(`dropdown.items.${item.titleKey}`)}
              </motion.div>
            </Link>
          ))}
        </motion.div>

        <div className="w-[610px] h-[610px] hidden lg:block">
          <motion.img src={image} className="w-full h-full" />
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
