import React from "react";
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";
import icon3 from "../../assets/icons/bespoke-diamond.svg";
import icon1 from "../../assets/icons/bespoke-selection.svg";
import icon2 from "../../assets/icons/bespoke-gift.svg";
import { useTranslation } from "react-i18next";

function HomePageBespoke({ color, icon }: any) {
  const { t } = useTranslation();
  const data = [
    {
      image: icon3,
      title: t("bespoke.steps.step1.title"),
      description: t("bespoke.steps.step1.description")
    },
    {
      image: icon1,
      title: t("bespoke.steps.step2.title"),
      description: t("bespoke.steps.step2.description")
    },
    {
      image: icon2,
      title: t("bespoke.steps.step3.title"),
      description: t("bespoke.steps.step3.description")
    }
  ];

  return (
    <>
      <div className="text-4xl md:text-6xl font-medium font-big-calson mb-4 text-center">
        {t("bespoke.title")}
      </div>
      <div className="text-center text-base md:text-lg font-jost max-w-md mx-auto">
        {t("bespoke.subtitle")}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 mt-8 md:mt-16">
        {data.map((item, index) => (
          <div key={index} className="p-6 md:p-8 bg-white rounded-2xl shadow-md">
            <div className="flex justify-center">
              <img src={item.image} className="object-cover h-[82px] mb-6 md:mb-8" />
            </div>
            <div className="text-[#161616] text-xl md:text-2xl font-medium font-jost mb-3 text-center">
              {item.title}
            </div>
            <div className="text-[#5C5C5C] text-base md:text-lg font-jost text-center">
              {item.description}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 md:mt-16 text-white flex justify-center">
        <Link to={"/bespoke#getStart"} className={`button ${color} flex items-center`}>
          {t("bespoke.cta")}
          <GoArrowUpRight className="text-xl ml-2" />
        </Link>
      </div>
    </>
  );
}

export default HomePageBespoke;
