import React from "react";
import round from "../../assets/images/collections/diamond-types/ROUND.svg"
import OVAL from "../../assets/images/collections/diamond-types/OVAL.svg"
import EMERALD from "../../assets/images/collections/diamond-types/EMERALD.svg"
import CUSHION from "../../assets/images/collections/diamond-types/RADIANT.svg"
import MARQUISE from "../../assets/images/collections/diamond-types/MARQUISE.svg"
import PRINCESS from "../../assets/images/collections/diamond-types/CUSHION.svg"
import PEAR from "../../assets/images/collections/diamond-types/PEAR.svg"
import RADIANT from "../../assets/images/collections/diamond-types/RADIANTO.svg"
import HEART from "../../assets/images/collections/diamond-types/HEART.svg"


type DiamondShape = {
  name: string;
  description: string;
  images: string;
};

type DiamondShapesProps = {
  onSelectShape: (shape: string) => void;
  selectedShape: string;
};

const product_shapes = [
  {
    name: "ROUND",
    description: "A classic and timeless shape",
    images: round,
  },
  {
    name: "OVAL",
    description: "An elegant and sophisticated shape",
    images: OVAL,
  },
  {
    name: "EMERALD",
    description: "A rectangular shape with cut corners",
    images: EMERALD,
  },
  {
    name: "CUSHION",
    description: "A modern and edgy shape",
    images: CUSHION,
  },
  {
    name: "MARQUISE",
    description: "An elongated and dramatic shape",
    images: MARQUISE,
  },
  {
    name: "PRINCESS",
    description: "A romantic and whimsical shape",
    images: PRINCESS,
  },
  {
    name: "PEAR",
    description: "A unique and versatile shape",
    images: PEAR,
  },
  {
    name: "RADIANT",
    description: "A romantic and whimsical shape",
    images: RADIANT,
  },
  {
    name: "HEART",
    description: "A romantic and whimsical shape",
    images: HEART,
  },
];

function DiamondShapes({  onSelectShape, selectedShape }: DiamondShapesProps) {
  return (
    <div className="overflow-x-auto mb-16 diamondShape">
      <div className="flex gap-8">
        {product_shapes.map((item: DiamondShape, index: number) => (
          <div
            className={` ${selectedShape.toLowerCase() === item.name.toLowerCase() ? "border-b border-b-gray-400" : "" } min-w-[100px] flex flex-col gap-5 cursor-pointer`}
            key={index}
            onClick={() => onSelectShape(item.name)}
          >
            <img
              src={item.images}
              alt={item.name}
              className="max-h-[108px] object-cover bg-white"
            />
            <div className="text-center font-jost mt-auto">{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiamondShapes;
