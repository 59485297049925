import React, { useEffect, useState } from "react";
import NavBar from "./navbar";
import Footer from "./footer";
import { Outlet } from "react-router-dom";

function Layout({
  showNav = true,
  showFooter = true,
}: {
  showNav?: boolean;
  showFooter?: boolean;
}) {

  
  
  return (
    <>
      {!showNav ? null : <NavBar />}
      {/* <OfferBanner /> */}
      <Outlet/>
      {!showFooter ? null : <Footer />}
    </>
  );
}

export default Layout;
