"use client";
import React from "react";
import { toast } from "sonner";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../molecules/loader/loader";
import instagram from "../../assets/icons/instagram-white.svg";
import whatsapp from "../../assets/icons/whatsapp-white.svg";
import copy from "../../assets/icons/copy-white.svg";
import image from "../../assets/images/contact-us-page/contact-us-frame.webp";
import right from "../../assets/icons/arrow-right-up-black.svg";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use translation hook

  interface DomainDetails {
    email: string;
    phone: string;
    address: string;
  }

  interface DomainMapping {
    [domain: string]: DomainDetails;
  }

  function getDomainDetails(domain: string): DomainDetails {
    const domainDetails: DomainMapping = {
      "https://www.ecorocksdenia.com": {
        email: "bespoke@ecorocks.es",
        phone: "+34653169579",
        address: "Denia",
      },
      "https://ecorocks.co.za": {
        email: "bespoke@ecorocks.co.za",
        phone: "+27828968254",
        address: "South Africa",
      },
    };

    return (
      domainDetails[domain] || {
        email: "bespoke@ecorocks.es",
        phone: "+34653169579",
        address: "Denia",
      }
    );
  }

  let contactEmail: any;

  if (typeof window !== "undefined") {
    const { email } = getDomainDetails(window.location.origin);
    contactEmail = email;
  }

  const email2 = "support@ecorocks.es";
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    name: "",
    email: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("common.requiredField")),
    email: Yup.string()
      .email(t("common.invalidEmail"))
      .required(t("common.requiredField")),
  });

  const onSubmit = (values: any) => {
    const payload = {
      from: values.email,
      to: "bespoke@ecorocks.es",
      subject: "Contact us",
      data: [
        { key: "Name", value: values.name },
        { key: "Email", value: values.email },
        { key: "description", value: values.description },
      ],
    };
    setLoading(true);

    fetch("https://api.ecorocksdenia.com/api/auth/contact", {
      body: JSON.stringify(payload),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          navigate("/thankYou");
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="widow-main h-fit lg:h-screen min-h-[screen] lg:min-h-auto bg-[#161616]  pb-[32px] lg:pb-[80px] sm:pb-0">
      {loading && <Loader />}
      <div className="widow-inner h-auto lg:h-full lg:max-h-[calc(100vh-140px)] flex flex-col lg:flex-row gap-[24px] pb-[32px] pt-[70px] sm:pt-[32px]">
        <div className="basis-[100%] lg:basis-[45%]  h-full rounded-[16px] relative overflow-hidden">
          <img
            src={image}
            className="absolute w-full h-full left-0 top-0 object-cover "
          />
          <div className="flex flex-col h-full w-full  relative py-[40px] xl:py-[60px] px-[20px] sm:px-[32px] xl:px-[60px]  gap-[20px]">
            <h1 className="max-w-[400px] text-[#CDB972] text-[40px] leading-[50px] sm:text-[54px] sm:leading-[60px] xl:text-[60px] xl:leading-[64px] font-big-calson">
              {t("contactUs.title")}
            </h1>
            <div className="flex gap-3 ">
              <span className="max-w-[400px] text-[#ffffff] text-[18px] leading-[26px] ">
                {contactEmail}
              </span>
              <button
                onClick={() => {
                  if (navigator && navigator?.clipboard) {
                    navigator.clipboard.writeText(contactEmail || "");
                    toast(t("contactUs.toastCopied"), {
                      duration: 1000,
                      position: "bottom-center",
                    });
                  }
                }}
              >
                <img src={copy} />
              </button>
            </div>
            <div className="flex gap-3  max-w-[400px] items-center justify-between mt-auto">
              <Link
                to="https://www.instagram.com/ecorocks_?igsh=eWtndHk0aWM4cWZs"
                target="_blank"
                className="w-full h-[58px] border border-white flex items-center justify-center gap-4 text-white text-[18px] leading-[24px]"
              >
                <img
                  src={instagram}
                  className="w-[18px] sm:w-[26px]"
                />{" "}
                <span>{t("contactUs.instagram")}</span>
              </Link>

              <Link
                to="https://wa.me/+34653169579"
                target="_blank"
                className="w-full h-[58px] border border-white flex items-center justify-center gap-4 text-white text-[18px] leading-[24px]"
              >
                <img
                  src={whatsapp}
                  className="w-[18px] sm:w-[26px]"
                />{" "}
                <span>{t("contactUs.whatsapp")}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="basis-[100%] lg:basis-[55%] bg-[#222222] h-full rounded-[16px] ">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="flex flex-col h-full w-full  relative py-[40px] xl:py-[60px] px-[20px] sm:px-[32px] xl:px-[60px]  gap-[32px] sm:gap-[40px]">
              <Field
                name="name"
                type="text"
                className="bg-transparent border-b-[#5C5C5C] border-b-[1px] pb-[10px] outline-none placeholder:text-[#8A8A8A] text-white "
                placeholder={t("contactUs.form.namePlaceholder")}
              />

              <Field
                name="email"
                className="bg-transparent border-b-[#5C5C5C] border-b-[1px] pb-[10px] outline-none placeholder:text-[#8A8A8A] text-white"
                placeholder={t("contactUs.form.emailPlaceholder")}
                type="email"
              />

              <Field
                name="description"
                className="bg-transparent border-b-[#5C5C5C] border-b-[1px] pb-[10px] outline-none placeholder:text-[#8A8A8A] text-white"
                placeholder={t("contactUs.form.messagePlaceholder")}
              />

              <div className="flex  mt-auto flex-col gap-[32px]">
                <p className="text-[16px] leading-[24px] text-white">
                  {t("contactUs.form.privacyPolicy")}
                </p>
                <motion.button
                  whileHover="on_hover"
                  style={{ fontWeight: "600" }}
                  variants={{
                    on_hover: {
                      scale: 1.04,
                    },
                  }}
                  className="flex items-center text-[#161616] bg-white  py-[18px] justify-center gap-[16px] text-[18px] leading-[28px]"
                  type="submit"
                >
                  <span>{t("contactUs.form.submitButton")}</span>
                  <img src={right} className="w-[12px] sm:w-[16px]" />
                </motion.button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
