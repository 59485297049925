import React from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.css";
import image from "../../../assets/images/home-page/homepage-aboutUs-center.svg" 

const ImageGrid = ({
  imageLeft,
  imageTop,
  imageBottom,
}: {
  imageLeft?: string;
  imageTop?: string;
  imageBottom?: string;
}) => {
  return (
    <div>
      <div className="w-full max-w-[506px] aspect-square flex gap-[12px] relative">
        <div className="absolute w-full h-full left-0 top-0 flex items-center justify-center z-[3] ">
          <div className="w-[100px] aspect-square bg-white rounded-full">
            <motion.img
              className={`w-full aspect-square `}
              //"
              src={image}
            />
          </div>
        </div>
        <div className="h-full  w-full ">
          <motion.img
            viewport={{ once: true }}
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            className="w-full h-full object-cover"
            src={imageLeft}
          />
        </div>
        <div className="h-full  w-full flex flex-col gap-[12px]">
          <div className="h-full  w-full">
            <motion.img
              viewport={{ once: true }}
              initial={{ scale: 0 }}
              transition={{ delay: 0 }}
              whileInView={{ scale: 1 }}
              className="w-full h-full object-cover"
              src={imageTop}
            />
          </div>
          <div className="h-full  w-full">
            <motion.img
              viewport={{ once: true }}
              initial={{ scale: 0 }}
              transition={{ delay: 0.2 }}
              whileInView={{ scale: 1 }}
              className="w-full h-full object-cover"
              src={imageBottom}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;
