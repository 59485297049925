import React from 'react'
import PageHeaderPrimary from '../molecules/PageHeaderPrimary'
import HomepageProducts from '../homepage/homepageProducts'
import FAQs from '../homepage/faqs'
import DontMiss from '../homepage/dontMiss'
import ComprehensiveGuide from './comprehensive-guide'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import HowItWork from './how-it-work'

function LabDiamonds() {
  const {t} = useTranslation()
  return (
    <div>
      <PageHeaderPrimary title_1={t("dropdown.items.lab_diamonds")} />
        
        <ComprehensiveGuide />
        
        <HowItWork/>
        
        <FAQs/>
        <DontMiss />
    </div>
  )
}

export default LabDiamonds
