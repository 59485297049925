"use client";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import image1 from "../../assets/images/miss-update/miss-update-1.svg";
import image2 from "../../assets/images/miss-update/miss-update-2.jpg";
import image3 from "../../assets/images/miss-update/miss-update-3.svg";
import image4 from "../../assets/images/miss-update/miss-update-4.webp";
import rightArrow from "../../assets/icons/right-arrow-long-white.svg";
import instagramImage from "../../assets/icons/instagram-white.svg";
import { useTranslation } from "react-i18next";

const updates = [
  { id: "1", image: image1, final: false },
  { id: "2", image: image2, final: false },
  { id: "3", image: image3, final: false },
  { id: "4", image: image4, final: true },
];

function DontMiss() {
  const { t } = useTranslation();

  return (
    <div className="widow-main py-[50px] md:py-[100px] bg-[#F4F1EC] relative max-w-full overflow-hidden">
      <div className="widow-inner">
        <p className="text_28 text-[#CDB972] font-[500] font-big-calson text-center">
          {t("dont_miss.heading")}
        </p>
        <p className="text_60 text-[#161616] font-[600] font-big-calson text-center my-[16px]">
          {t("dont_miss.title")}
        </p>
        <p className="text_14 text-[#2D2D2D] font-[400] text-center max-w-[630px] mx-auto">
          {t("dont_miss.description")}
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[24px] mt-[32px] sm:mt-[60px] relative z-[2]">
          {updates.map((item, index) => (
            <motion.div
              initial={{ x: 100, y: 100, opacity: 0 }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: { delay: 0.3 * index },
              }}
              viewport={{ once: true }}
              key={item.id}
              className="h-[330px] md:h-[400px] lg:h-[330px] xl:h-[387px] w-full bg-gray-200 overflow-hidden relative px-[18px] py-[32px] flex items-center justify-center"
            >
              <img src={item.image} className="object-cover absolute top-0 left-0 h-full w-full" />

              {item?.final ? (
                <Link
                  to="https://www.instagram.com/ecorocks_?igsh=eWtndHk0aWM4cWZs"
                  target="_blank"
                  className="relative z-[1]"
                >
                  <img
                    src={instagramImage}
                    className="w-[40px] h-[40px] mx-auto"
                  />
                  <p className="text-white text_34 text-center my-[24px] xl:my-[32px]">
                  {t("dont_miss.button_text")}
                  </p>
                  <img
                    src={rightArrow}
                    className="w-[50px] mx-auto"
                  />
                </Link>
              ) : null}
            </motion.div>
          ))}
        </div>

        <div className="absolute left-0 bottom-0 h-[250px] w-full bg-[#161616] z-[1]" />
      </div>
    </div>
  );
}

export default DontMiss;
