import FAQs from './FAQ'
import PageHeaderSecondary from '../molecules/PageHeaderSecondary'
import { useTranslation } from 'react-i18next'

function Faqs() {
  const {t} = useTranslation()

  return (
    <div>
      <PageHeaderSecondary title_1={t("dropdown.items.faqs")} />
      <FAQs/>
    </div>
  )
}

export default Faqs
