"use client";
import React from "react";

const CustomCarousel = ({
  emblaRef,
  slides = [],
  selectedIndex,
}: {
  emblaRef?: any;
  slides?: any;
  selectedIndex?: any;
}) => {
  return (
    <section className="max-w-[100%] m-auto">
      <div className="overflow-hidden" ref={emblaRef}>
        <div
          className=" touch-pan-y flex gap-[20px] lg:gap-[50px] xl:gap-[76px]"
          style={{
            backfaceVisibility: "hidden",
          }}>
          {slides.map((item?: any, index?: any) => (
            <div
              className={`${selectedIndex === index ? "" : "opacity-45"} bg-white h-auto md:h-[336px] min-w-[80%] sm:min-w-[500px] md:min-w-[600px] xl:min-w-[800px] items-center flex flex-col md:flex-row p-[32px] xl:p-[60px] gap-[32px] xl:gap-[40px]`}
              key={item?.id}>
              <img
                src={item?.avatar}
                className="xl:mb-auto w-[120px] h-[120px] xl:w-[168px] xl:h-[168px] shrink-0 rounded-full overflow-hidden"
              />
              <div>
                <p className="text-[#2D2D2D] text-center md:text-left text_18">
                  {item?.description}
                </p>
                <p className="text-[#161616] text-center md:text-left text_24 font-[500] mt-[24px]">
                  {item?.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomCarousel;
