"use client";
import * as React from "react";
import ControlButtons from "./ControlButtons";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton, useDotButton } from "./DotButton";
import CustomCarousel from "../molecules/CustomCarousel";
import client1 from "../../assets/images/our-client/ourclient3.svg";
import client2 from "../../assets/images/our-client/ourclient2.svg";
import client3 from "../../assets/images/our-client/ourclient1.svg";
import whiteicon from "../../assets/icons/dot-white.svg";
import blackIcon from "../../assets/icons/dot-black.svg";
import { useTranslation } from "react-i18next";

const options = { startIndex: 1 };

function OurClients() {

  const {t} = useTranslation()
  
  const clients = [
    {
      id: "1",
      avatar: client1,
      name: "Jay & Chloe",
      description:
        t("review.first"),
    },
  
    {
      id: "2",
      avatar: client2,
      name: "Chris & Beth",
      description:
      t("review.second"),
    },
  
    {
      id: "3",
      avatar: client3,
      name: "Garry & Chloe",
      description:
      t("review.third"),
    },
  ];
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <>
      <div className=" bg-[#f4f1ec] py-[50px] md:py-[100px]">
        <div className="widow-main">
          <div className="widow-inner">
            <div className="flex justify-between items-center mb-[32px] sm:mb-[60px]">
              <h1 className="text_60 text-[#161616]">{t("ourclient")}</h1>
              <div className="hidden sm:flex">
                <ControlButtons emblaApi={emblaApi} />
              </div>
            </div>
          </div>
        </div>
        <CustomCarousel
          slides={clients}
          emblaRef={emblaRef}
          selectedIndex={selectedIndex}
        />

        <div className="flex gap-[5px] justify-center mt-[40px]">
          {scrollSnaps.map((_, index) => (
            <DotButton key={index} onClick={() => onDotButtonClick(index)}>
              <img
                src={
                  selectedIndex === index
                    ? whiteicon
                    : blackIcon
                }
              />
            </DotButton>
          ))}
        </div>
      </div>
    </>
  );
}

export default OurClients;
