"use client";
import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { IoAddSharp } from "react-icons/io5";
import { IoIosRemove } from "react-icons/io";
import { useTranslation } from "react-i18next";

interface Question {
  id: string;
  title: string;
  description: string;
}

interface FAQsProps {
  showTitle?: boolean;
}

const FAQs: React.FC<FAQsProps> = ({ showTitle = true }) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<string | null>(null);

  // Use type assertion to treat the translation result as an array of Question
  const questions: Question[] = t("faq.questions", {
    returnObjects: true,
  }) as Question[];

  return (
    <div className="widow-main py-[50px] md:py-[100px] bg-[#ffffff]">
      <div className="widow-inner">
        {showTitle && (
          <h1 className="text_60 mb-[32px] sm:mb-[48px] text-[#161616]">
            {t("faq.title")}
          </h1>
        )}

        <Accordion
          type="single"
          collapsible
          onValueChange={setCurrent}
          className="">
          {questions.map((item) => {
            const isActive = current === item.id;
            return (
              <AccordionItem
                value={item.id}
                key={item.id}
                className={`border-none ${
                  isActive ? "bg-[#094C3B]" : "bg-[#F4F1EC]"
                } px-[32px] mb-[8px] rounded-[8px]`}>
                <AccordionTrigger
                  className="hover:no-underline text-left"
                  Icon={
                    isActive ? (
                      <IoIosRemove className="text-2xl text-white shrink-0 transition-transform duration-200" />
                    ) : (
                      <IoAddSharp className="text-2xl text-black shrink-0 transition-transform duration-200" />
                    )
                  }>
                  <span className={`text_24 font-[500] ${isActive ? "text-[#ffffff]" : "text-[#161616]"}`}>
                    {item.title}
                  </span>
                </AccordionTrigger>
                <AccordionContent>
                  <span className={`text_15 font-[500] ${isActive ? "text-[#ffffff]" : "text-[#161616]"}`}>
                    {item.description}
                  </span>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQs;
