import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function GetStartedStep4() {
  const { t } = useTranslation();

  return (
    <div className="mt-14 flex flex-col gap-5">
      <p className="text_34 font-[600] text-[#CDB972] text-center ">
        {t("getStarted.step6title")}
      </p>
      <div className=" w-3/4 mx-auto mt-5">
        <Field
          as="textarea"
          name="description"
          placeholder={t("getStarted.formLabels.description")}
          rows={5}
          className=" w-full  px-4 py-5 border border-white rounded-[8px] hover:bg-white/35 bg-white/20 text-white "
        />
      </div>
      <p className="text-[#D0D0D0] font-jost text-lg m-auto mb-10 md:w-2/3 w-full text-center">
        {t("getStarted.step6note")}{" "}
      </p>{" "}
    </div>
  );
}

export default GetStartedStep4;
