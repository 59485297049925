import React, { useEffect, useState } from "react";
import getAddressByDomain, { footerLinks } from "./data";
import { Link } from "react-router-dom";
import whitelogo from "../../assets/icons/logo-white.svg";
import whitelogotext from "../../assets/icons/logo-text-white.svg";
import mail from "../../assets/icons/message-white.svg";
import phoneicon from "../../assets/icons/phone-white.svg";
import locationicon from "../../assets/icons/location-white.svg";
import { useTranslation } from "react-i18next";

interface DetailItem {
  header?: boolean;
  title: string;
  noi18?: boolean;
  path?: string;
  leftIcon?: string;
}

function Footer() {
  const { t } = useTranslation();

  const [details, setdetails] = useState<DetailItem[]>([]);

  useEffect(() => {
    const { email, phone, address } = getAddressByDomain();

    setdetails([
      {
        noi18: false,
        header: true,
        title: t("footer.contact"),
      },
      {
        noi18: true,
        title: email,
        path: `mailto:${email}`,
        leftIcon: mail,
      },
      {
        noi18: true,
        title: phone,
        path: `tel:${phone}`,
        leftIcon: phoneicon,
      },
      {
        noi18: true,
        title: address,
        leftIcon: locationicon,
      },
    ] as DetailItem[]);
  }, []);

  const combinedFooterLinks = [...footerLinks, details];
  return (
    <div className="widow-main bg-[#161616] pt-[100px] pb-[40px] max-w-[100vw]">
      <div className="widow-inner flex flex-col lg:flex-row items-start gap-[44px] lg:gap-[32px] flex-wrap">
        <div className="flex flex-col items-center mx-auto lg:mx-0 max-w-[354px] gap-[32px]">
          <img src={whitelogo} className="w-[84px]" />
          <img src={whitelogotext} className="w-[187px]" />
          <p className="text-left md:text-center lg:text-left text-[14px] leading-[22px] text-white">
            {t("footer.description")}
          </p>
        </div>

        <div className="flex flex-col md:flex-row w-full lg:w-auto flex-1 justify-center lg:justify-end gap-[32px] mx-auto lg:mx-0 flex-wrap lg:flex-nowrap">
          {combinedFooterLinks.map((column, index) => {
            return (
              <div
                key={`${index}`}
                className="flex flex-col items-start gap-[20px] w-full max-w-[181px]"
              >
                {column.map((item: DetailItem) => (
                  <Link
                    key={item?.title}
                    to={item?.path ? item?.path : "#"}
                    className={
                      item?.header
                        ? "text-[#1E7654] font-[500] text-[16px] leading-[24px] cursor-default"
                        : "text-[#ffffff] font-[400] text-[14px] leading-[22px flex items-center gap-3 hover:text-[#CDB972] cursor-pointer"
                    }
                  >
                    {item?.leftIcon && <img src={item?.leftIcon} />}
                    {item?.noi18 ? item.title : t(`footer.${item.title}`) }
                  </Link>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className="widow-inner flex flex-col lg:flex-row flex-wrap items-start border-t border-t-[#5C5C5C] mt-[40px] pt-[40px] gap-[32px]">
        <p className="text-[14px] leading-[22px] text-white">
          {t("footer.copyright")}
        </p>

        <div className="flex flex-wrap flex-1 justify-end gap-[32px]">
          <a
            href="/terms-and-conditions"
            className={
              "text-[#ffffff] font-[400] text-[14px] leading-[22px flex items-center gap-3 hover:text-[#CDB972] cursor-pointer"
            }
          >
            <div> {t("footer.terms_and_conditions")}</div>
          </a>

          <a
            href="/privacy-policy"
            className={
              "text-[#ffffff] font-[400] text-[14px] leading-[22px flex items-center gap-3 hover:text-[#CDB972] cursor-pointer"
            }
          >
            <div> {t("footer.privacy_policy")}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
