"use client";

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import style from "./header.module.css";
import { motion } from "framer-motion";
import { toast } from "sonner";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import translation hook

// Modal custom styles
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    borderRadius: "10px",
    zIndex: 999,
  },
};

// Form validation schema using Yup
const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  consultationType: Yup.string().required("Consultation type is required"),
  consultationDate: Yup.date().required("Consultation date is required"),
  consultationTime: Yup.string().required("Consultation time is required"),
});

function PageHeaderPrimary({
  title_1,
  title_2,
  description,
}: {
  title_1?: string;
  title_2?: string;
  description?: string;
}) {
  const { t } = useTranslation(); // Initialize translation hook

  // State to handle modal visibility
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission

  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = "hidden"; // Disable background scrolling
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = ""; // Restore background scrolling
    setIsSubmitted(false); // Reset the thank-you message on close
  };

  // Function to handle both API calls
  const handleSubmit = (values: any) => {
    setLoading(true);

    // Payload for the first API (email)
    const payload1 = {
      from: values.email,
      to: "bespoke@ecorocks.es",
      subject: "Appointment Mail",
      data: [
        { key: "Name", value: values.firstName + " " + values.lastName },
        { key: "Email", value: values.email },
        { key: "Phone", value: values.phone },
        { key: "Consultation Type", value: values.consultationType },
        {
          key: "Consultation Date",
          value: moment(values.consultationDate).format("DD/MMM/YYYY"),
        },
        { key: "Consultation Time", value: values.consultationTime },
      ],
    };

    // Payload for the second API (appointment saving)
    const payload2 = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      consultationType: values.consultationType,
      consultationDate: moment(values.consultationDate).format("DD/MMM/YYYY"),
      consultationTime: values.consultationTime,
    };

    // First API (sending email)
    fetch("https://api.ecorocksdenia.com/api/auth/contact", {
      body: JSON.stringify(payload1),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((err) => {
        toast.error(t("page_header_primary.toast_messages.email_failed"));
      });

    // Second API (saving appointment)
    const api = "https://api.ecorocksdenia.com/api/appointment/book";
    // const api = "http://localhost:5000/api/appointment/book"
    fetch(api, {
      body: JSON.stringify(payload2),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          toast.success(t("page_header_primary.toast_messages.success"));
          setIsSubmitted(true); // Show thank you section
          navigate("/thankYou");
        }
      })
      .catch((err) => {
        toast.error(t("page_header_primary.toast_messages.appointment_failed"));
      })
      .finally(() => {
        setLoading(false); // Stop loading spinner
      });
  };

  return (
    <div className="widow-main bg-[#161616] max-w-full overflow-hidden">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h2 className="text-center text-2xl mb-4">
          {t("page_header_primary.modal_title")}
        </h2>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            consultationType: "",
            consultationDate: "",
            consultationTime: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4 flex flex-col ">
                <label htmlFor="firstName">
                  {t("page_header_primary.first_name_label")}
                </label>
                <Field name="firstName" className="input-field" />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="lastName">
                  {t("page_header_primary.last_name_label")}
                </label>
                <Field name="lastName" className="input-field" />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="email">
                  {t("page_header_primary.email_label")}
                </label>
                <Field name="email" type="email" className="input-field" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="phone">
                  {t("page_header_primary.phone_label")}
                </label>
                <Field name="phone" className="input-field" />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="consultationType">
                  {t("page_header_primary.consultation_type_label")}
                </label>
                <Field
                  name="consultationType"
                  as="select"
                  className="input-field"
                >
                  <option value="">
                    {t("page_header_primary.consultation_type_options.select")}
                  </option>
                  <option value="store">
                    {t("page_header_primary.consultation_type_options.store")}
                  </option>
                  <option value="virtual">
                    {t("page_header_primary.consultation_type_options.virtual")}
                  </option>
                </Field>
                <ErrorMessage
                  name="consultationType"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="consultationDate">
                  {t("page_header_primary.consultation_date_label")}
                </label>
                <Field
                  name="consultationDate"
                  type="date"
                  className="input-field"
                />
                <ErrorMessage
                  name="consultationDate"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="consultationTime">
                  {t("page_header_primary.consultation_time_label")}
                </label>
                <Field
                  name="consultationTime"
                  type="time"
                  className="input-field"
                />
                <ErrorMessage
                  name="consultationTime"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="flex justify-end mt-6">
                <button
                  type="button"
                  onClick={closeModal}
                  disabled={loading}
                  className="mr-4 bg-gray-500 text-white py-2 px-4 rounded-full"
                >
                  {t("page_header_primary.cancel_button")}
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-[#e9e6d9] text-[#094C3B] py-3 px-8 rounded-full transition"
                >
                  {loading
                    ? t("page_header_primary.loading_text")
                    : t("page_header_primary.submit_button")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <div className="widow-inner pt-[40px] sm:pt-[80px] pb-[100px] sm:pb-[150px] relative">
        <motion.img
          initial={{ x: 250, y: -250, opacity: 0, rotate: "90deg" }}
          whileInView={{ x: 0, y: 0, opacity: 1, rotate: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          src="/images/home-page/homepage-header-top-right.webp"
          className="absolute top-0 right-[0] w-[50%] md:w-[424px] z-[2] opacity-15 md:opacity-100"
        />

        <motion.img
          initial={{ x: -250, y: 250, opacity: 0, rotate: "90deg" }}
          whileInView={{ x: 0, y: 0, opacity: 1, rotate: 0 }}
          transition={{ duration: 1.3, delay: 1 }}
          viewport={{ once: true }}
          className="absolute left-[0] bottom-0 w-[50%] md:w-[424px] z-[1] opacity-15 md:opacity-100"
          src="/images/home-page/homepage-header-bottom-left.webp"
        />
        <motion.h1
          initial={{ y: 40, opacity: 0 }}
          whileInView={{
            y: 1,
            opacity: [0, 1, 0.7, 0.5, 0.8, 0.9, 1],
          }}
          className={`${style["text-effect"]} text_48 md:text_96 relative z-[3] break-words text-center px-4`}
        >
          {title_1}
        </motion.h1>

        <motion.h1
          initial={{ y: 40, opacity: 0 }}
          whileInView={{
            y: 1,
            opacity: [0, 1, 0.7, 0.5, 0.8, 0.9, 1],
            transition: { delay: 0.5 },
          }}
          className={`${style["text-effect"]} text_48 md:text_96 relative z-[3] break-words text-center px-4`}
        >
          {title_2}
        </motion.h1>

        <motion.p
          initial={{ y: 40, opacity: 0 }}
          whileInView={{
            y: 1,
            opacity: 1,
            transition: { delay: 0.8 },
          }}
          className="mt-[24px] mx-auto text-[#B9B9B9] text-center text_18 max-w-[95%] md:max-w-[495px] relative z-[4] min-h-20 px-4"
        >
          {description}
        </motion.p>

        {/* Button to open the modal */}
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
          className="flex justify-center mt-8"
        >
          <button
            onClick={openModal}
            className="bg-[#e9e6d9] text-[#094C3B] py-3 px-8 rounded-full hover:bg-[#e9e6d9] focus:outline-none  transition relative z-[5]"
          >
            {t("page_header_primary.book_appointment_button")}
            </button>
        </motion.div>

        {/* Modal for the form */}
      </div>
    </div>
  );
}

export default PageHeaderPrimary;
