import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import clsx from "clsx";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import englishlogo from "../../assets/icons/circle.png";
import spainlogo from "../../assets/icons/spain.png";
import { useTranslation } from "react-i18next";
import "../../i18n"; // Make sure to import the i18n configuration file

const GeoTranslate = () => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("language") || "en"
  );

  const setLanguage = (language: any) => {
    const currentLanguage = Cookies.get("language");
    if (currentLanguage !== language) {
      i18n.changeLanguage(language);
      Cookies.set("language", language);
      setSelectedLanguage(language);
    }
  };

  const handleLanguageChange = (newLanguage: any) => {
    setLanguage(newLanguage);
  };

  useEffect(() => {
    const savedLanguage = Cookies.get("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  return (
    <div>
      <Listbox
        value={selectedLanguage}
        onChange={(e) => handleLanguageChange(e)}
      >
        <ListboxButton
          className={clsx(
            "relative block w-full min-w-28 rounded-full py-1.5 pr-8 pl-3 text-left text-sm/6 text-black bg-white",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 uppercase flex gap-2"
          )}
        >
          <img
            src={selectedLanguage === "en" ? englishlogo : spainlogo}
            alt={selectedLanguage === "en" ? "english" : "spanish"}
            width={20}
            height={20}
          />
          {selectedLanguage === "en" ? "en" : "es"}
          <ChevronDownIcon
            className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
            aria-hidden="true"
          />
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          transition
          className={clsx(
            "z-[99999] w-[var(--button-width)] rounded-xl border border-white/5 bg-white/5 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
          )}
        >
          <ListboxOption
            value="en"
            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
          >
            <img src={englishlogo} alt="English" width={20} height={20} />
            <div className="text-sm/6 text-white">En</div>
          </ListboxOption>
          <ListboxOption
            value="es"
            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
          >
            <img src={spainlogo} alt="Spanish" width={20} height={20} />
            <div className="text-sm/6 text-white">Es</div>
          </ListboxOption>
        </ListboxOptions>
      </Listbox>
    </div>
  );
};

export default GeoTranslate;
