export const products_nav_items = [
  {
    title: "Bracelet",
    id: "1",
  },

  {
    title: "Earings",
    id: "2",
  },

  {
    title: "Chains",
    id: "3",
  },

  {
    title: "Pendants",
    id: "4",
  },

  {
    title: "Diamonds",
    id: "5",
  },
];

export default function getCurrencyByDomain() {
  const domain = window.location.host;

  switch (domain) {
    case 'ecorocksdenia.com':
    case 'ecorocks.es':
      return 'EURO';
    case 'ecorocks.co.za':
      return 'RAND';
    case 'weareecorocks.com':
      return 'GBP';
    default:
      return 'EURO'; // Default currency if domain doesn't match
  }
}


const fetchProducts = async () => {
 
  
};

export async function pickRandomProducts(count: any) {
  const response = await fetch(`https://api.ecorocksdenia.com/api/product/products?&currencyCode=${getCurrencyByDomain()}`);
  
  if (!response.ok) {
    throw new Error('Failed to fetch products'); // Handle fetch errors
  }

  const data = await response.json();
  const shuffledArray = data.data.sort(() => 0.5 - Math.random()); // Shuffle the array randomly
  return shuffledArray.slice(0, count || 4); // Return the first count elements or 4 by default
}


export const product_cut = [
  {
    id: 1,
    name: "Hidden Halo",
  },
  {
    id: 2,
    name: "Oval",
  },
];

export const product_ringSize = [
  {
    id: 1,
    size: "5mm+",
  },
  {
    id: 2,
    size: "6.4mm+",
  },
  {
    id: 3,
    size: "7.3mm+",
  },
  {
    id: 4,
    size: "8mm+",
  },
  {
    id: 5,
    size: "8.8mm+",
  },
  {
    id: 6,
    size: "9.4mm+",
  },
];
export const product_metal = [
  {
    name: "Platinum",
    id: 1,
  },
  {
    name: "18k Yellow Gold",
    id: 2,
  },
  {
    name: "18k White Gold",
    id: 3,
  },
];

export const product_carat = [
  {
    id: 1,
    name: "0.50ct",
  },
  {
    id: 2,
    name: "0.70ct",
  },
  {
    id: 3,
    name: "1.00ct",
  },
  {
    id: 4,
    name: "1.50ct",
  },
  {
    id: 5,
    name: "2.00ct",
  },
  {
    id: 6,
    name: "2.50ct",
  },
  {
    id: 7,
    name: "3.00ct",
  },
];
export const diamond_shapes = [
  {
    name: "Round",
  },
  {
    name: "Oval",
  },

  {
    name: "Emerald",
  },
  {
    name: "Princess",
  },
  {
    name: "Marquise",
  },
  {
    name: "Cushion",
  },
  {
    name: "Radiant",
  },
  {
    name: "Pear",
  },
];
export const product_shapes = [
  {
    name: "ROUND",
    description: "A classic and timeless shape",
    images: "/images/collections/diamond-types/ROUND.svg",
  },
  {
    name: "OVAL",
    description: "An elegant and sophisticated shape",
    images: "/images/collections/diamond-types/OVAL.svg",
  },
  {
    name: "EMERALD",
    description: "A rectangular shape with cut corners",
    images: "/images/collections/diamond-types/EMERALD.svg",
  },
  {
    name: "CUSHION",
    description: "A modern and edgy shape",
    images: "/images/collections/diamond-types/RADIANT.svg",
  },
  {
    name: "MARQUISE",
    description: "An elongated and dramatic shape",
    images: "/images/collections/diamond-types/MARQUISE.svg",
  },
  {
    name: "PRINCESS",
    description: "A romantic and whimsical shape",
    images: "/images/collections/diamond-types/CUSHION.svg",
  },
  {
    name: "PEAR",
    description: "A unique and versatile shape",
    images: "/images/collections/diamond-types/PEAR.svg",
  },
  {
    name: "RADIANT",
    description: "A romantic and whimsical shape",
    images: "/images/collections/diamond-types/RADIANTO.svg",
  },
  {
    name: "HEART",
    description: "A romantic and whimsical shape",
    images: "/images/collections/diamond-types/HEART.svg",
  },
];

export const product_price_ranges = [
  { priceRange: "1,250 - 1,950" },
  { priceRange: "1,950 - 2,450" },
  { priceRange: "2,450 - 2,950" },
  { priceRange: "2,950 - 3,450" },
  { priceRange: "3,450 - 3,950" },
  { priceRange: "3,950 - 4,450" },
];
