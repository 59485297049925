import { useTranslation } from "react-i18next";
import image from "../../../../assets/icons/logo-gold.svg"

const Button = ({ title, onClick }: { title?: any; onClick?: any }) => {
  return (
    <button
    type="button"
      onClick={onClick}
      className="h-[64px] px-[30px] lg:px-[60px] flex items-center justify-center border border-white rounded-[8px] hover:bg-white/35 bg-white/20 text_16 text-white">
      <span className="text-inherit">{title}</span>
    </button>
  );
};

export const Step1 = ({ proceed = () => {} }: { proceed?: any }) => {

  const {t} = useTranslation()
  const items = [
    {
      title: t("getStarted.selectedring"),
    },
    // {
    //   title: "Dress Ring",
    // },
  ];

  return (
    <div className="w-full h-full flex flex-col gap-[40px] lg:gap-[60px] items-center justify-center">
      <img src={image} className="w-[87px]" />
      <p className="text_34 font-[600] text-[#CDB972] text-center">
        {t("getStarted.step1title")}
      </p>

      <div className="flex gap-[24px] w-full justify-center">
        {items.map((x?: any) => {
          return (
            <Button
              key={x?.title}
              title={x?.title}
              onClick={() => proceed(x?.title)}
            />
          );
        })}
      </div>
      <p className="text-[#D0D0D0] font-jost text-lg">
        {t("getStarted.step1note")}
      </p>
    </div>
  );
};
