import React from 'react'
import PageHeaderSecondary from '../molecules/PageHeaderSecondary'
import DontMiss from '../homepage/dontMiss'
import Collections from './collectionSection'
import { useTranslation } from 'react-i18next'

function Collection() {

  const {t} = useTranslation()

  return (
    <div>
              <PageHeaderSecondary title_1={t("dropdown.items.collections")} />
              <Collections/>
              <DontMiss />

    </div>
  )
}

export default Collection
