import React from "react";
import PageHeaderPrimary from "../molecules/PageHeaderPrimary";
import HomepageAbout from "./homepageAbout";
import HomePageBespoke from "./homepageBespoke";
import HomepageProducts from "./homepageProducts";
import WhoWeAre from "./whoWeAre";
import OurClients from "./ourClients";
import FAQs from "./faqs";
import DontMiss from "./dontMiss";
import { useTranslation } from "react-i18next";

function HomePage() {
  const {t} = useTranslation()

  return (
    <div>
      <PageHeaderPrimary
        title_1={t("homepage.title1")}
        title_2={t("homepage.title2")}
        description={t("homepage.description")}
      />
      <HomepageAbout />
      <div className="bg-[#094C3B] widow-main py-[50px] md:py-[100px] flex flex-col text-white overflow-hidden">
        <div className="widow-inner">
          <HomePageBespoke color="hover:bg-white text-white hover:text-black border-white" />
        </div>
      </div>
      <HomepageProducts />
      <WhoWeAre />
      <OurClients />
      <FAQs />
      <DontMiss />
    </div>
  );
}

export default HomePage;
