import React from "react";
import { useTranslation } from "react-i18next";
import './style.css';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="widow-main bg-[#ffffff]">
      <div className="widow-inner py-[100px] sm:py-[150px]">
        <h1>{t("PrivacyPolicy.title")}</h1>

        <h3>{t("PrivacyPolicy.promiseTitle")}</h3>
        <p>{t("PrivacyPolicy.promiseText")}</p>

        <h3>{t("PrivacyPolicy.infoTitle")}</h3>
        <p>{t("PrivacyPolicy.infoText1")}</p>
        <ul>
          {(t("PrivacyPolicy.infoList", { returnObjects: true }) as string[]).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>{t("PrivacyPolicy.infoNote")}</p>

        <h3>{t("PrivacyPolicy.usageTitle")}</h3>
        <p>{t("PrivacyPolicy.usageText")}</p>
        <ul>
          {(t("PrivacyPolicy.usageList", { returnObjects: true }) as string[]).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t("PrivacyPolicy.linksTitle")}</h3>
        <p>{t("PrivacyPolicy.linksText")}</p>

        <h3>{t("PrivacyPolicy.newsletterTitle")}</h3>
        <p>{t("PrivacyPolicy.newsletterText1")}</p>
        <ul>
          {(t("PrivacyPolicy.newsletterList", { returnObjects: true }) as string[]).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>{t("PrivacyPolicy.newsletterText2")}</p>

        <h3>{t("PrivacyPolicy.disclosuresTitle")}</h3>
        <p>{t("PrivacyPolicy.disclosuresText")}</p>

        <h3>{t("PrivacyPolicy.securityTitle")}</h3>
        <p>{t("PrivacyPolicy.securityText")}</p>

        <h3>{t("PrivacyPolicy.accessTitle")}</h3>
        <p>{t("PrivacyPolicy.accessText")}</p>

        <h3>{t("PrivacyPolicy.changesTitle")}</h3>
        <p>{t("PrivacyPolicy.changesText")}</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
