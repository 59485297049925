import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import Loader from "../../molecules/loader/loader";
import "./model.css"
import { useTranslation } from "react-i18next";

function ContactInfo({ isToggled, metal, ringCode, ringSize, quantity, setToggled }: any) {
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    name: "",
    email: "",
  };
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const navigate = useNavigate()

  const onSubmit = (values: any) => {
    const payload = {
      from: values.email,
      to: "bespoke@ecorocks.es",
      subject: "Ring Order",
      data: [
        { key: "Name", value: values.name },
        { key: "Email", value: values.email },
        { key: "ringCode", value: ringCode },
        { key: "metal", value: metal },
        { key: "stoneSize", value: ringSize },
        { key: "quantity", value: quantity },
        { key: "description", value: values.description },
      ],
    };
    setLoading(true);

    fetch("https://api.ecorocksdenia.com/api/auth/contact", {
      body: JSON.stringify(payload),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setToggled();
          navigate("/thankYou")
          // toast.success('Thank you for connecting with us. We will get back to you soon');
          
          setLoading(false);
        }else{
          toast.error("Some Error")
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)

      });
  };

  useEffect(() => {
    if (isToggled) {
      document.body.classList.add('no-scroll'); // Prevent scroll
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll'); // Clean up when component unmounts
    };
  }, [isToggled]);
  const {t} = useTranslation()


  return (
    <AnimatePresence>
      {isToggled && (
        <>
          <motion.div className="backdrop">
            {loading && <Loader />}
            <motion.div
              className="modalDiv w-full"
              initial={{ y: 10, x: "-50%", opacity: 0 }}
              animate={{ y: 20, opacity: 1 }}
              exit={{ y: 100, opacity: 0 }}
            >
              <div className="relative w-full lg:basis-[55%] bg-[#222222] h-full rounded-[16px]">
                {/* Close button */}
                <button
                  onClick={()=>setToggled()}
                  className="absolute top-4 right-4 text-white bg-[#444] hover:bg-[#666] p-2 rounded-full z-10"
                >
                  &times;
                </button>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form className="flex flex-col h-full w-full  relative py-[40px] xl:py-[60px] px-[20px] sm:px-[32px] xl:px-[60px] gap-[32px] sm:gap-[40px]">
                    <Field
                      name="name"
                      type="text"
                      className="bg-transparent border-b-[#5C5C5C] border-b-[1px] pb-[10px] outline-none placeholder:text-[#8A8A8A] text-white"
                      placeholder={t("getStarted.formLabels.legalName")}
                    />

                    <Field
                      name="email"
                      className="bg-transparent border-b-[#5C5C5C] border-b-[1px] pb-[10px] outline-none placeholder:text-[#8A8A8A] text-white"
                      placeholder={t("getStarted.formLabels.email")}
                      type="email"
                    />

                    <Field
                      name="description"
                      className="bg-transparent border-b-[#5C5C5C] border-b-[1px] pb-[10px] outline-none placeholder:text-[#8A8A8A] text-white"
                      placeholder={t("getStarted.formLabels.message")}
                    />

                    <div className="flex mt-auto flex-col gap-[32px]">
                      <motion.button
                        whileHover="on_hover"
                        style={{ fontWeight: "600" }}
                        variants={{
                          on_hover: {
                            scale: 1.04,
                          },
                        }}
                        className="flex items-center text-[#161616] bg-white text-[16px] w-fit py-[16px] px-[32px] rounded-[100px] gap-2 group"
                      >
                        <span>{t("getStarted.submitButton")}</span>
                      </motion.button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default ContactInfo;
