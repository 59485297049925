import React from "react";
import image from "../../assets//images/about-us-page/about-us-page-discover.svg"
import { useTranslation } from "react-i18next";

function AboutDiscover() {
  const { t } = useTranslation();
  return (
    <div className="widow-main py-[50px] md:py-[100px] relative z-[2] bg-[#F4F1EC]">
      <div className="widow-inner flex flex-col lg:flex-row items-center xl:items-start justify-between gap-[24px] xl:gap-[80px]">
        <div className="w-full">
          <p className="text_48 text-[#161616] font-[500] mt-[24px] xl:mt-[32px] whitespace-nowrap">
          {t("about.discover_section.Personalised_Service")},<br />
           {t("about.discover_section.Exceptional_Value")}, <br /> 
          {t("about.discover_section.Ethically_Friendly")}
          </p>
          <img
            className="w-full lg:h-[400px] h-[280px] my-[32px] xl:my-[40px]"
            src={image}
          />

          <p className="text_14 text-[#2D2D2D] font-[400] my-[24px] xl:my-[32px]">
          {t("about.discover_section.description")}

          </p>
        </div>

        <div className="w-full lg:max-w-[560px] ">
          <p className="text-2xl font-jost text-[#2D2D2D] font-[500] mb-[24px] xl:my-[32px]">
          <span dangerouslySetInnerHTML={{ __html: t("about.discover_section.intro") }} />


          </p>
          <p className="text-2xl font-jost text-[#2D2D2D] font-[500] xl:my-[32px]">
          <span dangerouslySetInnerHTML={{ __html: t("about.discover_section.workshops") }} />

          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutDiscover;
