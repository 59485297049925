import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { GoArrowUpRight } from "react-icons/go";
import { pickRandomProducts } from "./data";
import { Link, useNavigate } from "react-router-dom";
import Product from "../interface/compnent";
import { useTranslation } from "react-i18next";

function HomepageProducts() {
    const [products, setProducts] = useState<Product[]>([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const randomProducts = await pickRandomProducts(4);
          setProducts(randomProducts);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
  
      fetchProducts();
    }, []);

    const {t} = useTranslation()
  
    return (
      <div className="widow-main py-[50px] md:py-[100px] max-w-[100%] overflow-hidden">
        <div className="widow-inner">
          <motion.p
            whileInView="visible"
            initial="hidden"
            viewport={{ once: true }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 150 },
            }}
            className="text_24 text-[#CDB972] font-[500]"
          >
            {t("homepage.BestOffer")}
          </motion.p>
          <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center mt-[24px] xl:mt-[32px] gap-y-[24px] gap-x-[32px]">
            <motion.p
              whileInView="visible"
              initial="hidden"
              viewport={{ once: true }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 150 },
              }}
              className="text_60 text-[#161616] font-[500]"
            >
              {t("homepage.BestOfferDescription")}
            </motion.p>
  
            <motion.p
              whileInView="visible"
              initial="hidden"
              viewport={{ once: true }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 150 },
              }}
            >
              <Link
                to="/collections"
                className="button hover:bg-[#094C3B] text-black hover:text-white border-[#161616]"
              >
                {t("homepage.allCollection")}
                <GoArrowUpRight className="text-xl my-auto ml-2" />
              </Link>
            </motion.p>
          </div>
  
          <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[24px] mt-[24px]">
            {products.length > 0 ? (
              products.map((item, index) => (
                <motion.div
                  whileHover="animate"
                  whileInView="show"
                  initial="hide"
                  variants={{
                    animate: { scale: 1.05 },
                    hide: { opacity: 0, y: 20 },
                    show: {
                      opacity: 1,
                      y: 0,
                      transition: { delay: 0.3 * index },
                    },
                  }}
                  className="flex flex-col w-full sm:w-[calc(50%-24px)] lg:w-[242px] xl:w-[282px] cursor-pointer"
                  key={item._id}
                  onClick={() => navigate(`/collections/collections/${item._id}`)}
                >
                  <img
                    src={item.mainImage}
                    className="h-full md:h-[300px] object-cover mb-[5px]"
                    alt={item.name}
                  />
                  <span className="text_14 text-[#8A8A8A]">{item.code}</span>
                  <span className="text_16 text-[#161616] font-[500]">
                    {item.name}
                  </span>
                  <span className="text_24 text-[#2D2D2D] font-[500] mt-[6px]">
                    {`${item.currencySign}${item.minPrice} to ${item.currencySign}${item.maxPrice}`}
                  </span>
                </motion.div>
              ))
            ) : (
              <p>Loading products...</p> // Optional loading state
            )}
          </div>
        </div>
      </div>
    );
  }
  
  export default HomepageProducts;
  