import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

function GetStartedStep5({errors}: any ) {

  const {t} = useTranslation()

  return (
    <div className="mt-20 flex flex-col gap-10">
      <p className="text_34 font-[600] text-[#CDB972] text-center ">
        {t("getStarted.step4title")}
      </p>
      <div className=' w-3/4 mx-auto mt-5'>
        <Field type='text' name="budget" placeholder={t("getStarted.enter_budget")} className=" w-full  px-4 h-16 border border-white rounded-[8px] hover:bg-white/35 bg-white/20 text-white " />
        {errors?.budget && <div className="text-red-300">{errors?.budget}</div>}
       </div>
      <p className="text-[#D0D0D0] font-jost text-lg m-auto mb-10 w-2/3 text-center">
      {t("getStarted.step4note")} </p>    </div>
  )
}

export default GetStartedStep5
